import {
  AddTask,
  ArrowBack,
  ArrowForward,
  PublishedWithChanges,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CancelEditDialog from "./CancelEditDialog";
import { DetailsStep } from "./DetailsStep";
import { DisplayAlert } from "./DisplayAlert";
import StepNavigation from "./StepNavigation";
import UploadPdf from "./UploadPdf";
import { UploadStep } from "./UploadStep";
import { apiAlertClient } from "../../../common/api-client";
import { mailTo, stripPhoneNumberFormatting } from "../../../common/utils";
import { AuthStateContext } from "../../../context";
import {
  AlertMode,
  BulletinPatchPayload,
  IUserPermission,
} from "../../../types";
import { DisabledComponentDialog } from "../../DisabledComponent";
import { parseUserPermissions } from "../../UserManagement/utils";
import "../alerts.scss";
import {
  BULLETIN_TYPE,
  alertSteps,
  defaultAlertProperties,
  defaultErrors,
  generateValidationCriteria,
} from "../constants";
import { AlertViewContext, SingleAlertContext } from "../context";
import { isContactEmpty } from "../utils";

interface UpdateAlertProps {
  mode: AlertMode;
}

export const UpdateAlert = ({ mode }: UpdateAlertProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["bulletin/create"]);
  const {
    alertProperties,
    setAlertProperties,
    setAlertProperty,
    errors,
    setErrors,

    refreshAlertData,
  } = useContext(SingleAlertContext);

  const { setUpdatedItems, policeServices } = useContext(AlertViewContext);
  const { currentUser, currentUserAgency } = useContext(AuthStateContext);

  const { id } = useParams();

  const [validationAttempted, setValidationAttempted] =
    useState<boolean>(false);

  const [isReadOnlyDialogOpen, setIsReadOnlyDialogOpen] = useState(false);
  const [alertPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );

  const readOnly = alertPermissions
    ? !alertPermissions.canCreateBulletin
    : false;

  useEffect(() => {
    if (readOnly) {
      setIsReadOnlyDialogOpen(true);
    }
  }, [readOnly]);

  function updateState(key, value) {
    setAlertProperty(key, value);
  }
  const handleUpdate = (key: any) => {
    return (value: any) => {
      updateState(key, value);
    };
  };

  const ReadOnlyDialog = ({ youtubeUrl }: { youtubeUrl?: string }) => {
    if (!readOnly) return null;
    return (
      <DisabledComponentDialog
        isDialogOpen={isReadOnlyDialogOpen}
        handleClose={() => navigate(`/rubialert/alerts`)}
        // youtubeUrl={youtubeUrl}
        title={t("read_only_title")}
        message={
          <Typography>
            Please contact{" "}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href={mailTo({
                email: "support@tryrubicon.com",
                subject: "Requesting Access to Create Bulletins",
                body: `Hello Rubicon Support Team, 

                I would like to request access to create bulletins.

                Thank you. 

                Sincerely,
                
                ${currentUser?.first_name} ${currentUser?.last_name} 
                ${currentUser?.email}`,
              })}
              color="secondary"
            >
              support@tryrubicon.com
            </Link>{" "}
            to request access.
          </Typography>
        }
      />
    );
  };

  const {
    policeService,
    type,
    associatedCrimes,
    occurrenceNumbers,
    incidentTimes,
    issuedTime,
    description,
    operationalArea,
    approvedBy,
    approvedByBadge,
    classification,
    bulletinId,
    contacts,
    rawAddresses,
    evidence_files,
    isDraft,
    pdf,
    recipients,
    originalLanguage,
    descriptionFr,
  } = alertProperties;

  if (mode === "edit" && !bulletinId && id) {
    updateState("bulletinId", id);
    refreshAlertData(id);
  }
  // const [issuedTime, setIssuedTime] = useState<Dayjs | null>(null);
  const [openCancelEdit, setOpenCancelEdit] = useState<boolean>(false);
  const [isUploadPdfModalOpen, setIsUploadPdfModalOpen] =
    useState<boolean>(true);
  const [evidenceFilesLoading, setEvidenceFilesLoading] =
    useState<boolean>(false);

  const [cleared, setCleared] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const steps = alertSteps;
  const [activeStep, setActiveStep] = useState<0 | 1 | 2>(0 as 0 | 1 | 2);

  const setBulletinID = (id: number) => {
    return new Promise((resolve) => {
      setAlertProperty("bulletinId", id);
      resolve(id);
    });
  };

  const [imageUploadedFlag, setImageUploadedFlag] = useState<boolean>(
    Object.keys(evidence_files).length > 0,
  );
  const [openImageCheckDialog, setOpenImageCheckDialog] =
    useState<boolean>(false);

  // Check if images have been uploaded
  useEffect(() => {
    if (Object.keys(evidence_files).length > 0) {
      setImageUploadedFlag(true);
    }
  }, [evidence_files]);

  const createBulletinResource = async () => {
    const res = await apiAlertClient.putNewBulletin();
    await setBulletinID(res.id);
    return res.id;
  };

  const handleSaveDraft = async () => {
    if (bulletinId) {
      saveBulletin(true);
    } else {
      try {
        const newBulletinId = await createBulletinResource();
        saveBulletin(true, newBulletinId);
      } catch (err) {
        console.error("Error while creating bulletin:", err);
      }
    }
  };

  // TODO: remove instances where we are overridding the alertProperties object
  /**
   * Save the alert to the database
   * should use only the alertProperties object
   */
  const saveBulletin = async (isDraft = false, id = bulletinId) => {
    const timezonedIssuedDate: string | null =
      (issuedTime !== null &&
        issuedTime.isValid() &&
        issuedTime.toISOString()) ||
      null;

    const geocoder = new google.maps.Geocoder();
    const geocodedPromises = rawAddresses?.map((address) =>
      address ? geocoder.geocode({ placeId: address?.place_id }) : null,
    );
    const filteredContacts = contacts
      ?.filter((contact) => isContactEmpty(contact) === false)
      .map((contact) => ({
        ...contact,
        phone_number: contact?.phone_number
          ? stripPhoneNumberFormatting(contact?.phone_number)
          : contact?.phone_number,
      }));

    let data: BulletinPatchPayload = {
      type: type as BULLETIN_TYPE,
      occurrence_number: [...occurrenceNumbers] || [],
      issued_time: (timezonedIssuedDate || null) as string,
      associated_crime: associatedCrimes.map((crime) => crime.crime) || [],
      incident_times: incidentTimes,
      location: { addresses: [] },
      description: (description || null) as string,
      description_fr: (descriptionFr || null) as string,
      original_language: (originalLanguage || null) as string,
      issuing_police_service: {
        id: (policeService ? policeService.id : null) as number,
      },
      contacts: filteredContacts,
      operational_area: operationalArea as string,
      approved_by: approvedBy as string,
      approved_by_badge: approvedByBadge as string,
      classification: classification,
      is_draft: isDraft,
    };
    const results = await Promise.all(geocodedPromises);

    results.forEach((result) => {
      if (result?.results) {
        const geoResult = result?.results[0];
        const rawResult = rawAddresses.filter((address) => {
          return address?.place_id === geoResult.place_id;
        })[0];

        let description = geoResult.formatted_address;
        let streetComponent = geoResult.address_components.filter((component) =>
          component.types.includes("street_number"),
        )[0];

        if (!streetComponent) {
          streetComponent = geoResult.address_components.filter((component) =>
            component.types.includes("intersection"),
          )[0];
        }
        const streetNumber = streetComponent ? streetComponent.long_name : "";

        if (!rawResult.structured_formatting) {
          description = rawResult.description;
        } else if (
          streetNumber &&
          !rawResult.structured_formatting.main_text.includes(streetNumber)
        ) {
          description = `${rawResult.structured_formatting.main_text} - ${geoResult.formatted_address}`;
        }
        data = {
          ...data,
          location: {
            addresses: [
              ...data.location.addresses,
              {
                description,
                place_id: geoResult.place_id,
                address_components: geoResult.address_components,
                geometry: {
                  lat: geoResult.geometry.location.lat(),
                  lng: geoResult.geometry.location.lng(),
                },
                location_type: geoResult.geometry.location_type,
                types: geoResult.types,
                plus_code: geoResult.plus_code,
                location_classification: rawResult.location_classification,
                other_location_classification:
                  rawResult.other_location_classification,
                structured_formatting: rawResult.structured_formatting,
              },
            ],
          },
        };
        setUpdatedItems((prev) => [...prev, data]);
      }
    });
    return apiAlertClient
      .patchBulletin(data, id)
      .then((res) => {
        setUpdatedItems((prev) => [...prev, data]);
        queryClient.invalidateQueries({
          queryKey: ["alerts"],
          refetchType: "all",
        });
        return res;
      })
      .catch((err) => {
        console.log("data", data);
        toast.error(err);
        throw err;
      });
  };

  const handleNext = async () => {
    setValidationAttempted(true);
    const validationMap = await generateValidationCriteria(alertProperties);
    const hasErrors = Object.keys(validationMap).some((key) => {
      if (key === "contactName") {
        return Object.values(validationMap[key]).some(
          (value) => value === true,
        );
      }
      if (key === "addresses") {
        return validationMap[key].some(
          (address) => !address.isValid || !address.hasLocationType,
        );
      }
      if (key === "incidentTimes") {
        // check if all incident times are valid
        // if a time has a time_type not_applicable or unknown, then it is false
        // otherwise, a time has a time_type and start_date is not null, then it is false
        // otherwise, it is true
        return (validationMap[key] = !incidentTimes.every(
          (incidentTime) =>
            incidentTime.time_type === "not_applicable" ||
            incidentTime.time_type === "unknown" ||
            (incidentTime.time_type && incidentTime.start_date !== null),
        ));
      }

      return validationMap[key];
    });
    setErrors(validationMap);

    if (activeStep == 1 && !imageUploadedFlag) {
      setOpenImageCheckDialog(true);
    } else if (!hasErrors) {
      if (isDraft) {
        handleSaveDraft();
      } else {
        saveBulletin();
      }
      setActiveStep((prevActiveStep) => (prevActiveStep + 1) as 0 | 1 | 2);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1) as 0 | 1 | 2);
  };

  const addUserEmailContact = () => {
    const userEmail = currentUser?.email;
    if (userEmail !== null) {
      handleUpdate("contacts")([
        {
          email: userEmail,
          phone_number: "",
          extension: "",
          name: "",
          badge_number: "",
          error: false,
        },
      ]);
    }
  };

  const setUsersPoliceService = () => {
    if (policeServices && policeServices.length > 0) {
      if (mode === "edit" && policeService) {
        handleUpdate("policeService")(policeService);
      } else {
        const agency = policeServices.find(
          (service) => service.id === currentUserAgency?.id,
        );
        if (agency) {
          handleUpdate("policeService")(agency);
        } else {
          handleUpdate("policeService")(policeServices[0]);
        }
      }
    }
  };

  const resetState = () => {
    console.info("resetting state");
    setAlertProperties(defaultAlertProperties);
    // setIssuedTime(null);
    setActiveStep(0);
    setErrors(defaultErrors);
    addUserEmailContact();
    setCleared(true);
  };

  // Load data on page load
  useEffect(() => {
    if (!readOnly) {
      setUsersPoliceService();
      if (contacts.length === 0) addUserEmailContact();
    }
  }, [readOnly, policeServices?.length, currentUser?.email]);

  useEffect(() => {
    if (!readOnly && (mode === "add" || mode === "upload")) {
      resetState();
    } else {
      setCleared(true);
    }
    setUsersPoliceService();
  }, [mode, readOnly]);

  const showSaveDraftButton = () => {
    const shouldShowSaveDraft = mode === "add" || isDraft;
    if (shouldShowSaveDraft) {
      return (
        <Button
          variant="outlined"
          color="secondary"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            handleSaveDraft();
            toast.info(t("draft_saved"));
          }}
        >
          {t("save_draft")}
        </Button>
      );
    }
  };

  const handleCloseCancelEdit = (confirmed: boolean) => {
    setOpenCancelEdit(false);
    if (confirmed) {
      if (mode === "edit" && isDraft) {
        navigate(`/rubialert/drafts/${bulletinId}/single`);
      } else {
        navigate(`/rubialert/alerts/${bulletinId}/single`);
      }
    }
  };

  if (mode === "upload" && !bulletinId && isUploadPdfModalOpen) {
    if (readOnly)
      return (
        // <ReadOnlyDialog youtubeUrl="https://www.youtube.com/watch?v=T_KrYLW4jw8" />
        <ReadOnlyDialog />
      );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
          padding: "64px",
          gap: "16px",
          maxWidth: "var(--page-max-width)",
          width: "90%",
          margin: "10px auto",
          background: "#ffffff",
          boxShadow: "var(--card-shadow)",
          borderRadius: "3px",
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div>
          <UploadPdf
            setIsUploadPdfModalOpen={setIsUploadPdfModalOpen}
            setEvidenceFilesLoading={setEvidenceFilesLoading}
          />
        </div>
      </Box>
    );
  }
  if (readOnly) {
    return (
      // <ReadOnlyDialog youtubeUrl="https://www.youtube.com/watch?v=gvgBUY8iNO4" />
      <ReadOnlyDialog />
    );
  }

  return (
    <div className="alert-container">
      {mode === "edit" && (
        <div className="cancel-edit-container">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCancelEdit(true)}
          >
            {t("cancel_editing")}
          </Button>
        </div>
      )}
      <div className="navigation-container">
        {steps.map((label, index) => (
          <StepNavigation
            key={index}
            index={index}
            activeStep={activeStep}
            steps={steps}
            label={label}
          />
        ))}
      </div>
      {cleared && activeStep === 0 && (
        <DetailsStep
          policeService={policeService!}
          onPoliceServiceSelect={handleUpdate("policeService")}
          occurrenceNumbers={occurrenceNumbers}
          type={type ?? ""}
          setType={handleUpdate("type")}
          associatedCrimes={associatedCrimes}
          incidentTimes={incidentTimes}
          setIncidentTimes={handleUpdate("incidentTimes")}
          description={
            originalLanguage === "fr"
              ? (descriptionFr ?? "")
              : (description ?? "")
          }
          setDescription={handleUpdate("description")}
          updateState={updateState}
          validationAttempted={validationAttempted}
          setErrors={setErrors}
        />
      )}

      {activeStep === 1 && bulletinId && (
        <UploadStep
          bulletinID={bulletinId}
          mode={mode}
          imagesLoading={evidenceFilesLoading}
        />
      )}

      {activeStep === 2 && (
        <DisplayAlert
          mode="add"
          issuing_police_service={policeService ? policeService.name : ""}
          associatedCrimes={associatedCrimes.map((crime) => crime.crime)}
          occurrenceNumbers={occurrenceNumbers}
          issuedTime={issuedTime ? issuedTime?.toISOString() : null}
          description={description}
          pdf={pdf}
          evidences={evidence_files}
        />
      )}
      {/* BUTTONS */}
      <div className="alert-bottom-action-container">
        {activeStep > 0 && (
          <>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<ArrowBack />}
              className="mr-2"
              onClick={() => {
                handleBack();
              }}
            >
              {t("back")}
            </Button>
            {activeStep < 3 && showSaveDraftButton()}
          </>
        )}
        {activeStep === 0 && showSaveDraftButton()}
        {activeStep < 2 && (
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForward />}
            onClick={() => {
              handleNext();
            }}
          >
            {t("next")}
          </Button>
        )}
        {activeStep === 2 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <Typography variant="caption" sx={{ textAlign: "center", mt: 2 }}>
              {t("next_step")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                mode === "add" || isDraft ? (
                  <AddTask />
                ) : (
                  <PublishedWithChanges />
                )
              }
              onClick={async () => {
                try {
                  const response = await saveBulletin(false, bulletinId);
                  console.log("Response from saveBulletin", response);
                  toast.info(
                    mode === "add" || isDraft
                      ? t("success_message")
                      : t("success_message_update"),
                  );
                  navigate(
                    `/rubialert/alerts?bulletinId=${bulletinId}&similarTo=${bulletinId}&share=true`,
                  );
                } catch (error) {
                  console.error("Failed to save bulletin:", error);
                }
              }}
            >
              {mode === "add" || isDraft ? t("finalize") : t("update_alert")}
            </Button>
          </div>
        )}
      </div>
      {/* DIALOGS */}
      <Dialog
        open={openImageCheckDialog}
        onClose={() => setOpenImageCheckDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("would_you_like_to_add_pictures")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("pictures_enhance_bulletins")}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setImageUploadedFlag(true);
              setOpenImageCheckDialog(false);
            }}
          >
            {t("skip")}
          </Button>
          <Button onClick={() => setOpenImageCheckDialog(false)} autoFocus>
            {t("add_pictures")}
          </Button>
        </DialogActions>
      </Dialog>

      <CancelEditDialog
        title={t("cancel_editing")}
        text={t("cancel_edit_text")}
        open={openCancelEdit}
        onClose={handleCloseCancelEdit}
      />
    </div>
  );
};
