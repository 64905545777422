// Import necessary libraries and components
import { Add, Clear } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Dayjs } from "dayjs";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

//Internal Imports
import styles from "./AdvancedDetails.module.css";
import { SingleAlertContext } from "../../context";

const AdvancedDetails: React.FC = () => {
  const { alertProperties, setAlertProperty, errors, setErrors } =
    useContext(SingleAlertContext);
  const { t } = useTranslation("bulletin/create");

  const issuedTime = alertProperties.issuedTime;

  const [isExpanded, setIsExpanded] = useState(issuedTime ? true : false);

  const handleAccordionChange = (
    _event: React.SyntheticEvent,
    newIsExpanded: boolean,
  ) => {
    setIsExpanded(newIsExpanded);
  };

  return (
    <div>
      <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
        <AccordionSummary expandIcon={<Add />}>
          <Typography>
            <b>{t("advanced")}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography variant="body2">
              {t("issued_date_time_description")}
            </Typography>

            <div className={styles.issuedDateContainer}>
              <DateTimePicker
                label={t("issued_date_time_label")}
                format="YYYY-MMM-DD   hh:mm A"
                ampm
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",
                    sx: { width: "100%" },
                  },
                }}
                value={issuedTime}
                disableFuture
                onChange={(newValue) => {
                  if (newValue) setAlertProperty("issuedTime", newValue);
                }}
              />
              {issuedTime && (
                <Tooltip title={t("clear_issued_date_time")} placement="top">
                  <IconButton
                    aria-label="delete"
                    onClick={() => setAlertProperty("issuedTime", null)}
                  >
                    <Clear />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AdvancedDetails;
