import { Link, Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router";

import { apiUserClient } from "../../common/api-client";
import { mailTo } from "../../common/utils";
import { AuthStateContext } from "../../context";
import { IUserPermission } from "../../types/typesAlerts";
import { DisabledComponentDialog } from "../DisabledComponent";
import { parseUserPermissions } from "../UserManagement/utils";

function ReportV2Analytics({ cisoDisplay }: { cisoDisplay: boolean }) {
  const [iframeUrl, setIframeUrl] = useState("");
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthStateContext);
  const [reportPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );
  const canViewDashboardRV2 = reportPermissions
    ? reportPermissions.canViewDashboardRV2
    : true;

  const getMetabaseURL = () => {
    apiUserClient
      .getMetabaseURL(cisoDisplay ? 3 : 2, orgId ?? "")
      .then((res) => {
        setIframeUrl(res.metabase_url);
      });
  };

  useEffect(() => {
    getMetabaseURL();
  }, []);

  if (!canViewDashboardRV2) {
    return (
      <DisabledComponentDialog
        isDialogOpen={true}
        handleClose={() => {
          navigate(`/`);
        }}
        title="You do not have access to view analytics dashboard."
        message={
          <Typography>
            Please contact{" "}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href={mailTo({
                email: "support@tryrubicon.com",
                subject: "Requesting Access to View Analytics",
                body: `Hello Rubicon Support Team, 

                I would like to request access to view the analytics dashboard.

                Thank you. 

                Sincerely,

                ${currentUser?.first_name} ${currentUser?.last_name} 
                ${currentUser?.email}`,
              })}
              color="secondary"
            >
              support@tryrubicon.com
            </Link>{" "}
            to request access.
          </Typography>
        }
      />
    );
  }

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <iframe
        src={iframeUrl}
        style={{
          flexGrow: 1,
        }}
        allowTransparency
      ></iframe>
    </div>
  );
}

export default ReportV2Analytics;
