/* eslint-disable react/display-name */
import {
  Assignment,
  CameraEnhance,
  Campaign,
  FindInPage,
  Help,
  Inventory,
  PeopleAlt,
  PrivacyTip,
} from "@mui/icons-material";
import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState } from "react";
import { useSidebarState, useTheme } from "react-admin";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useDevEnvironment } from "../../DevContext";
import { RubiModal } from "../../common/RubiModal";
import {
  APP_BOLO_ALERT,
  APP_CANVAS,
  APP_EPARADE,
  APP_INTEL,
  APP_REPORT,
  APP_REPORT_V2,
  APP_USERS_ADMIN,
  NO_APP,
} from "../../common/constants";
import { getServiceConfig } from "../../common/serviceConfigurations";
import { mailTo } from "../../common/utils";
import { Permission } from "../../components/UserManagement/types";
import { userPermissionChecks } from "../../components/UserManagement/utils";
import { AuthStateContext } from "../../context";

export default ({ selectedApp, setSelectedApp }) => {
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const [open, setOpen] = useSidebarState();
  const [supportOpen, setSupportOpen] = useState(false);

  const { isDevEnv } = useDevEnvironment();

  const openSidebar = () => {
    if (!isSmall) {
      setOpen(true);
    }
  };
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthStateContext);
  const currentUserPermissions =
    currentUser?.permissions?.map((permission) => permission.title) || [];

  const permissionsChecks = userPermissionChecks(currentUserPermissions);
  const organizationConfig = getServiceConfig(
    currentUser?.rubi_org_id ?? "NA",
    isDevEnv,
  );
  const appPermissions = organizationConfig?.apps ?? [];

  interface ApplicationItemProps {
    app: string;
    icon: any;
    title: string;
    disabled: boolean;
    ariaLabel: string;
    navigateTo?: string;
    onClick?: () => void;
  }
  const ApplicationItem = ({
    app,
    icon,
    title,
    disabled,
    ariaLabel,
    navigateTo,
    onClick,
  }: ApplicationItemProps) => {
    return (
      <div className={"application-item"}>
        <Tooltip
          title={`${title} ${disabled ? " - Disabled" : ""}`}
          placement="right"
          arrow
        >
          <IconButton
            color="secondary"
            aria-label={ariaLabel}
            size="large"
            disableRipple
            sx={{
              backgroundColor: selectedApp === app ? "lightgrey" : undefined,
              opacity: disabled ? 0.5 : 1,
              "&:hover": {
                backgroundColor: disabled ? undefined : "#f4f4f4",
              },
            }}
            onClick={() => {
              if (disabled) {
                toast.clearWaitingQueue();
                toast.error(
                  "You do not have permission to access this app. Please contact support@tryrubicon.com if you would like to get access.",
                );
                return;
              }
              toast.clearWaitingQueue();

              if (onClick) {
                onClick();
              }

              if (navigateTo) {
                setSelectedApp(app);
                openSidebar();
                navigate(navigateTo);
              }
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  let reportV2NavigateTo = "/rubireportv2/reports";
  if (permissionsChecks.canViewReportsRV2) {
    reportV2NavigateTo = "/rubireportv2/reports";
  } else if (permissionsChecks.canViewCitizenEngageRV2) {
    reportV2NavigateTo = "/rubireportv2/citizen-engage";
  }

  const applicationItems: ApplicationItemProps[] = [
    {
      app: APP_REPORT,
      icon: <Assignment />,
      title: "Online Reporting",
      disabled:
        !permissionsChecks.canAccessReportsApp ||
        !appPermissions.includes(Permission.REPORT),
      ariaLabel: "RubiREPORT App",
      navigateTo: "/rubireport",
    },
    {
      app: APP_REPORT_V2,
      icon: <Assignment />,
      title: "Online Reporting V2",
      disabled:
        !permissionsChecks.canAccessReportsV2App ||
        !appPermissions.includes(Permission.REPORT_V2),
      ariaLabel: "RubiREPORT App V2",
      navigateTo: reportV2NavigateTo,
    },
    {
      app: "APP_REGISTRY",
      icon: <Inventory />,
      title: "Registry System",
      disabled:
        !permissionsChecks.canAccessRegistryApp ||
        !appPermissions.includes(Permission.REGISTRY),
      ariaLabel: "Registry System",
      navigateTo: "/rubiregistry/registries",
    },
    {
      app: APP_INTEL,
      icon: <FindInPage />,
      title: "Intelligence Management System",
      disabled:
        !permissionsChecks.canAccessReportsV2App ||
        !appPermissions.includes(Permission.INTEL),
      ariaLabel: "RubiINTEL",
      navigateTo: "/rubiintel/reports",
    },
    {
      app: APP_BOLO_ALERT,
      icon: <PrivacyTip />,
      title: "Bulletin Management System",
      disabled:
        !permissionsChecks.canAccessAlertsApp ||
        !appPermissions.includes(Permission.ALERT),
      ariaLabel: "RubiAlert App",
      navigateTo: "/rubialert/alerts",
    },
    {
      app: APP_CANVAS,
      icon: <CameraEnhance />,
      title: "Camera Registry",
      disabled:
        !permissionsChecks.canAccessCameraRegistryApp ||
        !appPermissions.includes(Permission.CAMERA_REGISTRY),
      ariaLabel: "RubiCANVAS App",
      navigateTo: "/rubicanvas/map",
    },
    {
      app: APP_EPARADE,
      icon: <Campaign />,
      title: "eParades System",
      disabled: true,
      ariaLabel: "RubiParade App",
      navigateTo: "/rubiparade/alerts",
    },
  ].map((item) => ({
    ...item,
    disabled: currentUser?.profile_update_required || item.disabled,
  }));

  if (isDevEnv) {
    applicationItems.push();
  }

  const enabledApps = applicationItems.filter((item) => !item.disabled);
  const disabledApps = applicationItems.filter((item) => item.disabled);

  const supportEmailBody = `Hello Rubicon Support, \n\nI'm experiencing an issue on the platform.\n\n [Describe the issue here]\n\n[Attach any screenshots if you have them]\n\nThank you, \n[Your Name]\n[Your Agency]\n[Your Contact Information]\n`;

  const SupportModal = () => (
    <RubiModal isModalOpenArg={supportOpen} setIsModalOpenArg={setSupportOpen}>
      <DialogTitle>Request Support</DialogTitle>
      <DialogContent>
        <Typography>
          Connect with the Rubicon team for support. Reach out if you have any
          issues using the system or would like to submit feedback on posisble
          improvements.
        </Typography>
        <br />
        <Typography>
          <b>General Support and Inquiries</b>
        </Typography>
        <Typography>
          <a
            href={mailTo({
              email: "support@tryrubicon.com",
              subject: "Rubicon Support Request",
              body: supportEmailBody,
            })}
          >
            support@tryrubicon.com
          </a>
        </Typography>
        <br />
        <a
          href={mailTo({
            email: "support@tryrubicon.com",
            subject: "Rubicon Support Request",
            body: supportEmailBody,
          })}
        >
          <Button color="primary" variant="contained">
            Send Email
          </Button>
        </a>
        <br />
        <br />
        <Typography>
          Book a Meeting:{" "}
          <a href="https://calendly.com/daniyar-toronto/chat-with-rubicon">
            Calendly
          </a>
        </Typography>
        <br />
        <Typography>
          <b>Technical Emergencies</b>
        </Typography>
        <Typography>
          Please call: <a href="tel:1-866-260-3236">1-866-260-3236</a>
        </Typography>
      </DialogContent>
    </RubiModal>
  );

  return (
    <>
      {(open || selectedApp === NO_APP) && (
        <div className="applications-bar">
          {enabledApps.map((item) => (
            <ApplicationItem key={item.app} {...item} />
          ))}
          {disabledApps.map((item) => (
            <ApplicationItem key={item.app} {...item} />
          ))}
          <div className="application-item-divider"></div>
          <ApplicationItem
            app={APP_USERS_ADMIN}
            icon={<PeopleAlt />}
            title="User Management"
            disabled={currentUser?.profile_update_required ?? false}
            ariaLabel="RubiUsers App"
            navigateTo={`/organizations/users`}
          />
          <ApplicationItem
            app={"support"}
            icon={<Help />}
            title="Request Support"
            disabled={false}
            ariaLabel="Request Support"
            onClick={() => setSupportOpen(true)}
          />
          <SupportModal />
        </div>
      )}
    </>
  );
};
