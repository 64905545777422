import { ArrowRight } from "@mui/icons-material";
import { Typography, useMediaQuery, Theme, Button } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTheme } from "react-admin";
import { Link, Element, scroller } from "react-scroll";
import { toast } from "react-toastify";

import styles from "./EParadeList.module.css";
import { DisplayAlert } from "./add/DisplayAlert";
import { apiAlertClient } from "../../common/api-client";

export const EParadeList = () => {
  const itemsPerPage = 100;
  const [groupedAlerts, setGroupedAlerts] = useState<any[]>([]);
  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const [activeDate, setActiveDate] = useState(null);

  function formatDate(dateString) {
    const today = moment().startOf("day"); // get start of today
    const inputDate = moment(dateString);

    const diffInDays = today.diff(inputDate, "days");
    let daysAgo;

    if (diffInDays === 0) {
      daysAgo = "Today";
    } else if (diffInDays === 1) {
      daysAgo = "Yesterday";
    } else {
      daysAgo = `${diffInDays} days ago`;
    }

    const dayOfWeek = inputDate.format("dddd"); // Changed this line

    const formattedDate = inputDate.format("MMM DD, YYYY");

    return {
      daysAgo: daysAgo,
      dayOfWeek: dayOfWeek,
      formattedDate: formattedDate,
    };
  }

  useEffect(() => {
    apiAlertClient.getBulletins(0, itemsPerPage).then((res) => {
      if (res?.items?.length > 0) {
        const sortedAlerts = res.items.sort(
          (a, b) =>
            new Date(b.time_created).valueOf() -
            new Date(a.time_created).valueOf(),
        );
        const grouped = sortedAlerts.reduce((grouped, alert) => {
          //Handle UTC to local date-time conversion
          let dateTime = new Date(alert.time_created);
          const offset = dateTime.getTimezoneOffset();
          dateTime = new Date(dateTime.getTime() - offset * 60 * 1000);
          const date = dateTime.toISOString().split("T")[0];

          // const date = alert.time_created.split("T")[0];
          if (!grouped[date]) {
            grouped[date] = [];
          }
          grouped[date].push(alert);
          return grouped;
        }, {});
        setGroupedAlerts(Object.entries(grouped));
      } else {
        toast.warning("There are no alerts for the eParade");
      }
    });
  }, []);

  const scrollToElement = (date) => {
    scroller.scrollTo(`date-${date}`, {
      duration: 500,
      smooth: true,
      containerId: "alert-list",
    });
  };

  // Handle the case when there are no alerts
  if (Object.keys(groupedAlerts).length === 0) {
    return <p>Getting eParade alerts if there are any</p>;
  }

  const DateTitle = ({ date }) => {
    const formattedDate = formatDate(date);

    return (
      <div className={styles.dateTitleContainer}>
        <Typography variant={isSmall ? "h6" : "h3"}>
          {formattedDate.dayOfWeek}
        </Typography>
        <Typography variant={isSmall ? "h5" : "h2"}>
          <b>{formattedDate.daysAgo}</b>
        </Typography>
        <Typography variant={isSmall ? "h6" : "h4"}>
          {formattedDate.formattedDate}
        </Typography>
      </div>
    );
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div id="alert-list" style={{ flex: 1, overflow: "auto" }}>
        {groupedAlerts.map(([date, alerts], index) => (
          <Element key={index} name={`date-${date}`}>
            <DateTitle date={date} />
            {alerts.map((bulletin, dayIndex) => (
              <div key={bulletin.id}>
                <div className={styles.line}>
                  <div className={styles.numberContainer}>
                    <Typography className={styles.numberText}>
                      #{dayIndex + 1}
                    </Typography>
                  </div>
                </div>
                <DisplayAlert
                  mode="parade"
                  issuing_police_service={bulletin.issuing_police_service.name}
                  associatedCrimes={bulletin.associated_crime}
                  occurrenceNumbers={bulletin.occurrence_number}
                  issuedTime={bulletin.issued_time}
                  description={bulletin.description}
                  pdf={bulletin.pdf}
                  evidences={bulletin.evidence_files}
                />
              </div>
            ))}
          </Element>
        ))}
      </div>
      <div className={styles.sideScrollContainer}>
        {groupedAlerts.map(([date], index) => (
          <Link
            key={index}
            to={`date-${date}`}
            spy={true}
            smooth={"easeInOutQuad"}
            duration={1000}
            offset={24}
            containerId="alert-list"
            onSetActive={() => setActiveDate(date)}
            spyThrottle={1000}
          >
            <Button
              variant="text"
              startIcon={activeDate === date ? <ArrowRight /> : <></>}
              color="secondary"
              size="large"
              fullWidth
            >
              {date}
            </Button>
          </Link>
        ))}
      </div>
    </div>
  );
};
