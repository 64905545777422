import dayjs from "dayjs";
import i18n from "i18next";

import { IFieldErrors } from "./context";
import {
  validateContactName,
  validateGeocodedAddresses,
  validateOperationalArea,
} from "./utils";
import {
  stripPhoneNumberFormatting,
  validateEmail,
  validatePhone,
} from "../../common/utils";
import { IAlertProperties, IContact, PlaceType } from "../../types";

export enum ALERT_STEP {
  DETAILS = "details",
  UPLOAD = "upload",
  REVIEW = "review",
}

const { DETAILS, UPLOAD, REVIEW } = ALERT_STEP;

export const alertSteps = [DETAILS, UPLOAD, REVIEW];

export const emptyContact: IContact = {
  email: "",
  phone_number: "",
  extension: "",
  name: "",
  badge_number: "",
  error: false,
};

export enum ALERT_CLASSIFICATION {
  PROTECTED_A = "protected_a",
  PROTECTED_B = "protected_b",
  UNCLASSIFIED = "unclassified",
}

export enum LOCATION_CLASSIFICATION {
  INCIDENT_ADDRESS = "Incident Address",
  HOME_ADDRESS = "Home Address",
  WORK_ADDRESS = "Work Address",
  FREQUENTED_ADDRESS = "Frequented Address",
  LAST_SEEN_ADDRESS = "Last Seen Address",
  SAFE_HOUSE_ADDRESS = "Safe House Address",
  SURETY_ADDRESS = "Surety Address",
  SCHOOL_ADDRESS = "School Address",
  HIDEOUT_ADDRESS = "Hideout Address",
  VICTIM_ADDRESS = "Victim Address",
  CONTACT_ADDRESS = "Contact Address",
  ARREST_ADDRESS = "Arrest Address",
  OTHER = "Other",
}

export const LOCATION_CLASSIFICATION_REFERENCE: Record<
  LOCATION_CLASSIFICATION,
  string
> = {
  "Incident Address": "incident_address",
  "Home Address": "home_address",
  "Work Address": "work_address",
  "Frequented Address": "frequent_address",
  "Last Seen Address": "last_seen_address",
  "Safe House Address": "safe_house_address",
  "Surety Address": "surety_address",
  "School Address": "school_address",
  "Hideout Address": "hideout_address",
  "Victim Address": "victim_address",
  "Contact Address": "contact_address",
  "Arrest Address": "arrest_address",
  Other: "other",
};

export enum USER_TYPE {
  INDEPENDENT = "independent",
  REGULAR = "regular",
}

export const defaultAlertProperties: IAlertProperties = {
  policeService: null,
  occurrenceNumbers: [],
  type: null,
  associatedCrimes: [],
  incidentTimes: [{ start_date: null, end_date: null, time_type: "exact" }],
  description: null,
  descriptionFr: null,
  originalLanguage: null,
  tagDict: {},
  issuedTime: null,
  operationalArea: null,
  approvedBy: null,
  approvedByBadge: null,
  classification: ALERT_CLASSIFICATION.PROTECTED_B,
  contacts: [emptyContact],
  rawAddresses: [null] as unknown as PlaceType[],
  evidence_files: {},
  isDraft: true,
  pdf: {},
  resolutions: [],
};

export enum ALERT_STATUS {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}
export const generateValidationCriteria = async (
  alertProperties: IAlertProperties,
) => {
  const {
    policeService,
    operationalArea,
    occurrenceNumbers,
    incidentTimes,
    type,
    associatedCrimes,
    description,
    contacts,
    rawAddresses,
    classification,
  } = alertProperties;

  const addressErrors: any[] = [];
  const res = await validateGeocodedAddresses(rawAddresses);
  res.forEach((address) => {
    addressErrors.push(address);
  });

  const validateContactEmail = (contact) => {
    if (contact.email === "" && contact.phone_number === "") {
      return true;
    } else if (contact.email !== "" && !validateEmail(contact.email)) {
      return true;
    } else {
      return false;
    }
  };

  const validateContactPhone = (contact) => {
    const strippedPhone = stripPhoneNumberFormatting(contact?.phone_number);
    if (contact.phone_number === "" && contact.email === "") {
      return true;
    } else if (contact.phone_number !== "" && !validatePhone(strippedPhone)) {
      return true;
    }
    return false;
  };
  const validationMap: IFieldErrors = {
    policeService: policeService ? false : true,
    operationalArea: policeService
      ? validateOperationalArea(policeService, operationalArea)
      : false,

    occurrenceNumbers: occurrenceNumbers.length === 0 ? true : false,
    classification:
      classification === ("" as ALERT_CLASSIFICATION) ||
      classification === null ||
      classification === undefined
        ? true
        : false,
    // incidentDate:
    //   incidentDate === null ||
    //   !incidentDate.isValid() ||
    //   incidentDate.isAfter(new Date())
    //     ? true
    //     : false,
    type: type ? false : true,
    incidentTimes: incidentTimes.length === 0 ? true : false,
    associatedCrimes: associatedCrimes.length === 0 ? true : false,
    description: description === "" || description === null ? true : false,
    addresses: addressErrors,
    contactEmail: contacts[0] ? validateContactEmail(contacts[0]) : false,
    contactPhone: contacts[0] ? validateContactPhone(contacts[0]) : false,
    contactName: validateContactName(contacts),
  };
  return validationMap;
};

export const defaultErrors: IFieldErrors = {
  policeService: false,
  operationalArea: false,
  occurrenceNumbers: false,
  incidentTimes: false,
  type: false,
  associatedCrimes: false,
  description: false,
  addresses: [],
  contactEmail: false,
  contactPhone: false,
  classification: false,
  contactName: [],
};

export enum BULLETIN_TYPE {
  "suspect_to_identify" = "suspect_to_identify",
  "vehicle_to_identify" = "vehicle_to_identify",
  "suspect_and_vehicle_to_identify" = "suspect_and_vehicle_to_identify",
  "police_information" = "police_information",
  "officer_safety" = "officer_safety",
  "property_to_locate" = "property_to_locate",
  "wanted" = "wanted",
  "warrant" = "warrant",
  "missing_person" = "missing_person",
  "intelligence" = "intelligence",
  "arrests_made" = "arrests_made",
  "other" = "other",
}

export enum RESOLUTION_REASONS {
  "Suspect Identified" = "Suspect Identified",
  "Arrests Made" = "Arrests Made",
  "Arrest Made" = "Arrest Made",
  "Other" = "Other",
  "Vehicle Located" = "Vehicle Located",
  "Vehicle Owner Identified" = "Vehicle Owner Identified",
  "Suspect and Vehicle Identified" = "Suspect and Vehicle Identified",
  "Expired" = "Expired",
  "Withdrawn" = "Withdrawn",
  "Threat Level Updated" = "Threat Level Updated",
  "Threat Mitigated" = "Threat Mitigated",
  "Alert Withdrawn" = "Alert Withdrawn",
  "Warrant Served" = "Warrant Served",
  "Warrant Cancelled" = "Warrant Cancelled",
  "Missing Person Found" = "Missing Person Found",
  "Intelligence Expired" = "Intelligence Expired",
  "Intelligence Withdrawn" = "Intelligence Withdrawn",
}

export const shortenedResolutionReasons: Record<RESOLUTION_REASONS, string> = {
  "Suspect Identified": "Identified",
  "Arrests Made": "Arrested",
  "Arrest Made": "Arrested",
  Other: "Other",
  "Vehicle Located": "Located",
  "Vehicle Owner Identified": "Owner Identified",
  "Suspect and Vehicle Identified": "Identified",
  Expired: "Expired",
  Withdrawn: "Withdrawn",
  "Threat Level Updated": "Updated",
  "Threat Mitigated": "Mitigated",
  "Alert Withdrawn": "Withdrawn",
  "Warrant Served": "Served",
  "Warrant Cancelled": "Cancelled",
  "Missing Person Found": "Found",
  "Intelligence Expired": "Expired",
  "Intelligence Withdrawn": "Withdrawn",
};

export const bulletinResolutions: Record<BULLETIN_TYPE, RESOLUTION_REASONS[]> =
  {
    suspect_to_identify: [
      RESOLUTION_REASONS["Suspect Identified"],
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],
    vehicle_to_identify: [
      RESOLUTION_REASONS["Vehicle Located"],
      RESOLUTION_REASONS["Vehicle Owner Identified"],
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],

    suspect_and_vehicle_to_identify: [
      RESOLUTION_REASONS["Suspect and Vehicle Identified"],
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],

    police_information: [
      RESOLUTION_REASONS["Expired"],
      RESOLUTION_REASONS["Withdrawn"],
      RESOLUTION_REASONS["Other"],
    ],

    officer_safety: [
      RESOLUTION_REASONS["Threat Level Updated"],
      RESOLUTION_REASONS["Threat Mitigated"],
      RESOLUTION_REASONS["Alert Withdrawn"],
      RESOLUTION_REASONS["Other"],
    ],

    wanted: [
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],

    warrant: [
      RESOLUTION_REASONS["warrant_served"],
      RESOLUTION_REASONS["warrant_cancelled"],
      RESOLUTION_REASONS["other"],
    ],

    missing_person: [
      RESOLUTION_REASONS["missing_person_found"],
      RESOLUTION_REASONS["other"],
    ],

    intelligence: [
      RESOLUTION_REASONS["intelligence_expired"],
      RESOLUTION_REASONS["intelligence_withdrawn"],
      RESOLUTION_REASONS["other"],
    ],

    arrests_made: [
      RESOLUTION_REASONS["arrests_made"],
      RESOLUTION_REASONS["arrest_made"],
      RESOLUTION_REASONS["other"],
    ],
    other: [RESOLUTION_REASONS["other"]],

    property_to_locate: [RESOLUTION_REASONS["other"]],
  };

export const crimeGroupedTypes = [
  // Person Crime
  { crime: "assault", group: "person_crime" },
  { crime: "bodily_harm", group: "person_crime" },
  { crime: "indecent_exposure", group: "person_crime" },
  { crime: "missing_person", group: "person_crime" },
  { crime: "kidnapping", group: "person_crime" },
  { crime: "sexual_assault", group: "person_crime" },

  { crime: "attempt_murder", group: "person_crime" },
  { crime: "homicide", group: "person_crime" },
  { crime: "street_crime", group: "person_crime" },
  { crime: "other_person_crime", group: "person_crime" },
  // Property Crime
  { crime: "auto_theft", group: "property_crime" },
  { crime: "residential_break_and_enter", group: "property_crime" },
  { crime: "commercial_break_and_enter", group: "property_crime" },
  { crime: "theft_under", group: "property_crime" },
  { crime: "theft_from_auto", group: "property_crime" },
  { crime: "theft", group: "property_crime" },
  { crime: "alcohol_theft_lcbo", group: "property_crime" },
  { crime: "alcohol_theft_other", group: "property_crime" },

  { crime: "pump_and_run", group: "property_crime" },
  { crime: "arson", group: "property_crime" },
  { crime: "shoplifting", group: "property_crime" },
  { crime: "distraction_theft", group: "property_crime" },

  { crime: "larceny", group: "property_crime" },
  { crime: "embezzlement", group: "property_crime" },
  { crime: "trespassing", group: "property_crime" },
  { crime: "mischief", group: "property_crime" },
  { crime: "recovered_property", group: "property_crime" },
  { crime: "other_property_crime", group: "property_crime" },
  // Robbery Crime

  { crime: "commercial_robbery", group: "robbery_crime" },
  { crime: "financial_robbery", group: "robbery_crime" },
  { crime: "carjacking_robbery", group: "robbery_crime" },
  { crime: "taxi_robbery", group: "robbery_crime" },
  { crime: "home_invasion_robbery", group: "robbery_crime" },
  { crime: "mugging_robbery", group: "robbery_crime" },
  { crime: "pursesnatch_robbery", group: "robbery_crime" },
  { crime: "other_robbery", group: "robbery_crime" },
  // Organized Crime

  { crime: "gang", group: "organized_crime" },
  { crime: "human_trafficking", group: "organized_crime" },
  { crime: "racketeering", group: "organized_crime" },
  { crime: "organized_theft", group: "organized_crime" },
  { crime: "money_laundering", group: "organized_crime" },
  { crime: "counterfeit_money", group: "organized_crime" },
  { crime: "other_organized_crime", group: "organized_crime" },
  // Drugs Crime
  { crime: "possession_of_drugs", group: "drugs_crime" },
  { crime: "manufacturing_of_drugs", group: "drugs_crime" },
  { crime: "distribution_of_drugs", group: "drugs_crime" },
  { crime: "drug_trafficking", group: "drugs_crime" },
  { crime: "other_drug_crime", group: "drugs_crime" },
  // Computer Crime
  { crime: "hacking", group: "computer_crime" },
  { crime: "cyberstalking", group: "computer_crime" },
  { crime: "online_fraud_and_scams", group: "computer_crime" },
  { crime: "identity_theft", group: "computer_crime" },
  { crime: "other_computer_crimes", group: "computer_crime" },
  // Other Crime
  { crime: "fraud", group: "other_crime" },
  { crime: "vice", group: "other_crime" },
  { crime: "warrants", group: "other_crime" },
  { crime: "canada_wide_warrants", group: "other_crime" },
  { crime: "unlawfully_at_large", group: "other_crime" },
  { crime: "evade_police_police_pursuit", group: "other_crime" },
  { crime: "weapons", group: "other_crime" },
  { crime: "other_not_on_the_list", group: "other_crime" },
];

export const TIME_TYPE = {
  EXACT: "exact",
  RANGE: "range",
  NOT_APPLICABLE: "not_applicable",
  UNKNOWN: "unknown",
  TIME_UNKNOWN: "time_unknown",
};
