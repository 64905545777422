import { $generateHtmlFromNodes } from "@lexical/html";
import { $convertToMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Info } from "@mui/icons-material";
import {
  TextField,
  Chip,
  Box,
  Button,
  Paper,
  Autocomplete,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import LexicalEditor, {
  customTransformers,
} from "../../../../common/LexicalEditor";
import {
  batchSendBulletins,
  shareBulletin,
  getBulletinGroups,
} from "../../../../common/api-client/apiAlertClient";
import { AuthStateContext } from "../../../../context";
import { IAlertGroup } from "../../../../types/typesAlerts";

enum PortalAccess {
  CHECKED = "checked",
  INDETERMINATE = "indeterminate",
  UNCHECKED = "unchecked",
}

const NotifyEmails = ({
  bulletinIds,
  closeShareDialog,
}: {
  bulletinIds: number[];
  closeShareDialog: () => void;
}) => {
  const [note, setNote] = useState("");
  const { t } = useTranslation("bulletin/share");
  const { currentUserGroups } = useContext(AuthStateContext);
  const [notify, setNotify] = useState<number[]>([]);
  const [portalAccessChecks, setPortalAccessChecks] = useState<number[]>([]);
  const [portalAccessUnchecks, setPortalAccessUnchecks] = useState<number[]>(
    [],
  );
  const [includeMyself, setIncludeMyself] = useState<boolean>(false);
  const [dataSent, setDataSent] = useState<
    {
      group_id: number;
      notify: boolean;
      portalAccess: PortalAccess;
    }[]
  >([]);

  // get all the groups that is not a self group
  const nonSelfGroups = currentUserGroups.filter(
    (group) => !group.is_self_group,
  );

  // we need to get the groups that is associated with the bulletins. We need to
  // get the groups one by one
  const [alreadySharedGroups, setAlreadySharedGroups] = useState<
    IAlertGroup[][]
  >([]);

  // for portal access, we need to check if the group is in
  // *all* the IAlertGroup[] in alreadySharedGroups, otherwise
  // if it is in any of the IAlertGroup[] in alreadySharedGroups,
  // it should be half checked (indeterminate)
  // if it is not in any of the IAlertGroup[] in alreadySharedGroups,
  const portalAccessCheckedStatus = (group: IAlertGroup) => {
    // if user make any change on frontend, it will be reflected in either
    // portalAccessChecks or portalAccessUnchecks, so we need to check if
    // the group is in either of the array
    if (portalAccessChecks.includes(group.id)) {
      return PortalAccess.CHECKED;
    } else if (portalAccessUnchecks.includes(group.id)) {
      return PortalAccess.UNCHECKED;
    }
    // if it's not there, it means user has not made any change, so we need to
    // check if the group is in any of the IAlertGroup[] in alreadySharedGroups
    if (
      alreadySharedGroups.every((groups) =>
        groups.some((g) => g.id === group.id),
      )
    ) {
      return PortalAccess.CHECKED;
    } else if (
      alreadySharedGroups.some((groups) =>
        groups.some((g) => g.id === group.id),
      )
    ) {
      return PortalAccess.INDETERMINATE;
    }
    return PortalAccess.UNCHECKED;
  };

  useEffect(() => {
    const fetchData = async () => {
      const groups = await Promise.all(
        bulletinIds.map(async (bulletinId) => {
          const response = await getBulletinGroups(bulletinId);
          return response.items.filter((item) => !item.is_self_group);
        }),
      );
      setAlreadySharedGroups(groups);
    };
    fetchData();
  }, [bulletinIds]);

  useEffect(() => {
    const tempDataSent: {
      group_id: number;
      notify: boolean;
      portalAccess: PortalAccess;
    }[] = [];
    nonSelfGroups.forEach((group) => {
      const portalAccess = portalAccessCheckedStatus(group);
      tempDataSent.push({
        group_id: group.id,
        notify: false,
        portalAccess: portalAccess,
      });
    });
    setDataSent(tempDataSent);
  }, [alreadySharedGroups]);

  const changeNotify = (group_id: number) => {
    if (notify.includes(group_id)) {
      setNotify((prev) => prev.filter((id) => id !== group_id));
    } else {
      setNotify((prev) => [...prev, group_id]);
    }
  };

  const changePortalAccess = (group_id: number) => {
    // step 1: find the group in dataSent
    const group = dataSent.find((data) => data.group_id === group_id);
    // step 2: if the group is uncheck or indeterminate, set it to checked, else if
    // it is checked, set it to uncheck
    if (
      group?.portalAccess === PortalAccess.UNCHECKED ||
      group?.portalAccess === PortalAccess.INDETERMINATE
    ) {
      // add the group_id to the portalAccessChecks
      setPortalAccessChecks((prev) => [...prev, group_id]);
      // remove the group_id from the portalAccessUnchecks if exists
      setPortalAccessUnchecks((prev) => prev.filter((id) => id !== group_id));
      setDataSent((prev) =>
        prev.map((data) =>
          data.group_id === group_id
            ? { ...data, portalAccess: PortalAccess.CHECKED }
            : data,
        ),
      );
    } else {
      // add the group_id to the portalAccessUnchecks
      setPortalAccessUnchecks((prev) => [...prev, group_id]);
      // remove the group_id from the portalAccessChecks if exists
      setPortalAccessChecks((prev) => prev.filter((id) => id !== group_id));
      setDataSent((prev) =>
        prev.map((data) =>
          data.group_id === group_id
            ? { ...data, portalAccess: PortalAccess.UNCHECKED }
            : data,
        ),
      );
    }
  };

  const SubmitButton = () => {
    const [editor] = useLexicalComposerContext();
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={
          portalAccessChecks.length === 0 &&
          portalAccessUnchecks.length === 0 &&
          notify.length === 0 &&
          !includeMyself
        }
        onClick={async () => {
          editor.update(async () => {
            // Convert the Lexical editor state to HTML instead of Markdown.
            const html = $generateHtmlFromNodes(editor);
            const markdown = $convertToMarkdownString(
              customTransformers,
              undefined,

              true,
            );
            setNote(markdown);
            const data: {
              bulletinIds: number[];
              includeMyself: boolean;
              notify: number[];
              add_groups: number[];
              remove_groups: number[];
            } = {
              bulletinIds,
              includeMyself,
              notify: [],
              add_groups: [],
              remove_groups: [],
            };
            data.notify = notify;
            data.add_groups = portalAccessChecks;
            data.remove_groups = portalAccessUnchecks;
            let isPortalAccessUpdated = false;
            let isNotificationSent = false;
            // Check if portal access is being modified
            if (data.add_groups.length > 0 || data.remove_groups.length > 0) {
              isPortalAccessUpdated = true;
              const res = await shareBulletin(
                data.add_groups,
                data.remove_groups,
                data.bulletinIds,
              );
            }
            // Check if notifications are being sent
            if (data.notify.length > 0 || data.includeMyself) {
              const res = await batchSendBulletins(
                data.bulletinIds,
                data.notify,
                html,
                data.includeMyself,
              );

              if (Object.keys(res.failed_to_send).length > 0) {
                let errorMsg = "Problem sending notifications\n";
                Object.entries(res.failed_to_send).forEach(([key, value]) => {
                  errorMsg += `bulletin ${key}: ${value}\n`;
                });
                toast.error(errorMsg);
              } else {
                isNotificationSent = true;
              }
            }
            // Determine the success message based on the operations performed
            if (isPortalAccessUpdated && isNotificationSent) {
              toast.success("Access updated & notification sent successfully");
            } else if (isPortalAccessUpdated) {
              toast.success("Access updated successfully");
            } else if (isNotificationSent) {
              toast.success("Notifications sent successfully");
            }
            closeShareDialog();
          });
        }}
      >
        {/* • Starting State - disabled [NOTIFY & UPDATE ACCESS]
        • If just notify checkboxes have been altered --> [NOTIFY]
        • If just access checkboxes have been altered --> [UPDATE ACCESS]
        • If both have been altered --> [UPDATE & GRANT ACCESS] */}
        {notify.length > 0 || includeMyself
          ? portalAccessChecks.length + portalAccessUnchecks.length > 0
            ? t("notify_and_update_access")
            : t("notify")
          : t("update_access")}
      </Button>
    );
  };

  return (
    <div className="p-4" style={{ maxWidth: "600px" }}>
      <div className="my-3">
        <Typography variant="h5" sx={{ width: "100%" }}>
          <b>{t("share_bulletinCount", { count: bulletinIds.length })}</b>
        </Typography>
      </div>
      <TableContainer component={Paper} sx={{ maxWidth: "600px" }}>
        <Table sx={{ maxWidth: "600px" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">
                <div className="d-flex justify-content-end gap-2">
                  {t("notify")}
                  <Tooltip title={t("notify_tooltip")}>
                    <Info />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell align="right">
                <div className="d-flex justify-content-end gap-2">
                  {t("portal_access")}
                  <Tooltip title={t("portal_access_tooltip")}>
                    <Info />
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow key="myself">
              <TableCell component="th" scope="row">
                {t("myself")}
              </TableCell>
              <TableCell align="right">
                <Checkbox
                  onChange={(e, checked) => {
                    setIncludeMyself(checked);
                  }}
                />
              </TableCell>

              <TableCell align="right">
                <Checkbox checked disabled />
              </TableCell>
            </TableRow>

            {nonSelfGroups.map((group) => (
              <TableRow key={group.id}>
                <TableCell component="th" scope="row">
                  <a
                    href={`/rubialert/manage-groups/${group.id}/members`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {group.name}
                  </a>
                </TableCell>
                <TableCell align="right">
                  <Checkbox onChange={(e) => changeNotify(group.id)} />
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      portalAccessCheckedStatus(group) ===
                      PortalAccess.INDETERMINATE
                        ? t("indeterminate_tooltip")
                        : ""
                    }
                  >
                    <Checkbox
                      checked={
                        portalAccessCheckedStatus(group) ===
                        PortalAccess.CHECKED
                      }
                      indeterminate={
                        portalAccessCheckedStatus(group) ===
                        PortalAccess.INDETERMINATE
                      }
                      onChange={(e) => {
                        changePortalAccess(group.id);
                      }}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* text area for note */}
      <div className="mt-2">
        <label htmlFor="note-editor" style={{ color: "gray" }}>
          {t("message")}
        </label>
        <LexicalEditor
          markdown={note}
          onChange={(markdown) => setNote(markdown)}
          editorButtons={[
            <div></div>,
            <SubmitButton key="submit"></SubmitButton>,
          ]}
          readOnly={false}
        />
      </div>
    </div>
  );
};

export default NotifyEmails;
