export enum AlertPermission {
  CREATE_BULLETIN = "alert.create_bulletin.",
  DASHBOARD = "alert.dashboard.",
  VIEW_BULLETIN = "alert.view_bulletin.",
  SHARE_BULLETIN = "alert.share_bulletin.",
}

export enum ReportV2Permission {
  CREATE_SURVEY = "report_v2.create_survey.",
  VIEW_REPORTS = "report_v2.view_reports.",
  VIEW_CITIZEN_ENGAGE = "report_v2.view_citizen_engage.",
  VIEW_DASHBOARD = "report_v2.view_dashboard.",
  VIEW_SETTINGS = "report_v2.view_settings.",
  UPDATE_REPORTS = "report_v2.update_reports.",
}

export enum Permission {
  LOGIN = "login.",
  ALERT = "app.alert.",
  REPORT = "app.report.",
  REPORT_V2 = "app.report_v2.",
  CAMERA_REGISTRY = "app.camera_registry.",
  REGISTRY = "app.registry.",
  INTEL = "app.intel.",
  RUBI_ORG_CREATE = "rubi_org.create.",
  RUBI_ORG_ANY_LIST_USERS = "rubi_org.any.list_users.",
  RUBI_ORG_OWN_LIST_USERS = "rubi_org.own.list_users.",
  RUBI_ORG_ANY_ADD_USER = "rubi_org.any.add_user.",
  RUBI_ORG_OWN_ADD_USER = "rubi_org.own.add_user.",
  RUBI_ORG_ANY_MODIFY_USER_APPS = "rubi_org.any.modify_user_apps.",
  RUBI_ORG_OWN_MODIFY_USER_APPS = "rubi_org.own.modify_user_apps.",
  RUBI_ORG_OWN_MANAGE_ADMINS = "rubi_org.own.manage_admins.",
  RUBI_ORG_ANY_MANAGE_ADMINS = "rubi_org.any.manage_admins.",
  RUBI_USER_ANY_EDIT_FIELDS = "rubi_user.any.edit_fields.",
  RUBI_USER_ORG_EDIT_FIELDS = "rubi_user.org.edit_fields.",
  RUBI_USER_ANY_READ_PERMISSIONS = "rubi_user.any.read_permissions.",
  RUBI_USER_ORG_READ_PERMISSIONS = "rubi_user.org.read_permissions.",
  RUBI_USER_ANY_WRITE_PERMISSIONS = "rubi_user.any.write_permissions.",

  ALERT_CREATE_BULLETIN = AlertPermission.CREATE_BULLETIN,
  ALERT_DASHBOARD = AlertPermission.DASHBOARD,

  REPORT_V2_CREATE_SURVEY = ReportV2Permission.CREATE_SURVEY,
  REPORT_V2_VIEW_REPORTS = ReportV2Permission.VIEW_REPORTS,
  REPORT_V2_VIEW_CITIZEN_ENGAGE = ReportV2Permission.VIEW_CITIZEN_ENGAGE,
  REPORT_V2_VIEW_DASHBOARD = ReportV2Permission.VIEW_DASHBOARD,
  REPORT_V2_VIEW_SETTINGS = ReportV2Permission.VIEW_SETTINGS,
  REPORT_V2_UPDATE_REPORTS = ReportV2Permission.UPDATE_REPORTS,
}

export interface CheckedUserPermissions {
  canManageApps: boolean;
  canManageAdmins: boolean;
  canModifyUsers: boolean;
  canCreateUsers: boolean;
  canAccessAlertsApp: boolean;
  canAccessReportsApp: boolean;
  canAccessReportsV2App: boolean;
  canAccessCameraRegistryApp: boolean;
  canLogin: boolean;
  canCreateBulletin: boolean;
  canAccessDashboard: boolean;
  canAccessRegistryApp: boolean;

  canCreateSurveyRV2: boolean;
  canViewReportsRV2: boolean;
  canViewCitizenEngageRV2: boolean;
  canViewDashboardRV2: boolean;
  canViewSettingsRV2: boolean;
  canUpdateReportsRV2: boolean;
}
