import { Theme, useMediaQuery } from "@mui/material";
import { useTheme } from "react-admin";
import { useTranslation } from "react-i18next";

import styles from "./TriUploadFile.module.scss";
import upload from "../images/upload.png";

interface TriUploadFileProps {
  uploadMessage?: string;
  fileInput: any;

  handleFileUpload: (event: any, files: any) => void;
  accept: "image/*" | "application/pdf" | "image/*,video/*";
  multiple: boolean;
  disabled?: boolean;
}
const TriUploadFile = ({
  uploadMessage,
  fileInput,
  handleFileUpload,
  accept,
  multiple,
  disabled = false,
}: TriUploadFileProps) => {
  const [theme] = useTheme() as any;
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));
  const { t } = useTranslation("bulletin/create/upload");
  const dropHandler = (event) => {
    if (disabled) return;
    event.preventDefault();

    const container = new DataTransfer();

    [...event.dataTransfer.files].forEach((file) => {
      if (accept === "image/*" && file.type.includes("image")) {
        container.items.add(file);
      }
      if (accept === "application/pdf" && file.type.includes("pdf")) {
        container.items.add(file);
      } else if (
        accept === "image/*,video/*" &&
        (file.type.includes("image") || file.type.includes("video"))
      ) {
        container.items.add(file);
      }
    });

    fileInput.current!.files = container.files;
    handleFileUpload(event, container.files);
  };

  function dragOverHandler(event) {
    if (disabled) return;
    event.preventDefault();
  }

  const handleClick = (event) => {
    if (disabled) return;
    event.preventDefault();
    event.stopPropagation();
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFilePaste = (event) => {
    if (disabled) return;
    event.preventDefault();
    const container = new DataTransfer();
    const clipBoardEvent: ClipboardEvent = event as ClipboardEvent;
    if (clipBoardEvent.clipboardData) {
      Array.from(clipBoardEvent.clipboardData.files).forEach(async (file) => {
        if (accept === "image/*" && file.type.includes("image")) {
          container.items.add(file);
        }
        if (accept === "application/pdf" && file.type.includes("pdf")) {
          container.items.add(file);
        } else if (
          accept === "image/*,video/*" &&
          (file.type.includes("image") || file.type.includes("video"))
        ) {
          container.items.add(file);
        }
      });
      fileInput.current!.files = container.files;
      handleFileUpload(event, container.files);
    }
  };

  return (
    <div
      className={`${styles.uploadBtn} ${disabled ? styles.disabledState : ""}`}
      onPaste={handleFilePaste}
    >
      <div className={styles.uploadBtnCaption}>
        <div className="my-3 d-flex justify-content-center">
          <img src={upload} className={styles.uploadBtnImage} alt="" />
          {uploadMessage
            ? uploadMessage.toUpperCase()
            : t("uploadPicturesVideos")}{" "}
        </div>
        <div className={styles.imageUploadContainer}>
          <div
            className={
              isSmall ? styles.imageUploadOptionSmall : styles.imageUploadOption
            }
            onClick={handleClick}
          >
            {t("uploadFromDevice")}
          </div>
          {!isSmall && (
            <div className={styles.pasteContainer}>{t("pasteCtrlV")}</div>
          )}

          {!isSmall && (
            <div
              className={styles.dragAndDrop}
              onDrop={dropHandler}
              onDragOver={dragOverHandler}
            >
              {t("dragAndDrop")}
            </div>
          )}
        </div>
      </div>
      <input
        ref={fileInput}
        onChange={(event) => handleFileUpload(event, event.target.files)}
        type="file"
        accept={accept}
        style={{ display: "none" }}
        multiple={multiple}
      />
    </div>
  );
};

export default TriUploadFile;
