import { CssBaseline, Grid, Link, Paper, Typography } from "@mui/material";
import { ReactElement, useState, useEffect } from "react";

import styles from "./Home.module.css";
// import bg1 from "../images/bg1.jpg";
import apsImage from "../images/aps-side-image.jpeg";
import bg2 from "../images/bg2.jpg";
import bg3 from "../images/bg3.jpg";
import ckpsImage from "../images/ckps-side-image.jpeg";
import logo from "../images/rubicon_portal_logo.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.tryrubicon.com/">
        TryRubicon.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const LoginWrapper = ({ children }: { children: ReactElement }) => {
  const [finalImage, setFinalImage] = useState(bg2);

  useEffect(() => {
    const num = Math.floor(Math.random() * 10);
    let newImage = bg2;

    if ([1, 2, 3].includes(num)) newImage = bg2;
    if ([4, 5, 6].includes(num)) newImage = bg3;

    // Ideally refactor this into a service configuration value
    if (location.pathname.includes("ckps")) {
      newImage = ckpsImage;
    } else if (location.pathname.includes("anishinabek")) {
      newImage = apsImage;
    }

    setFinalImage(newImage);
  }, []); // Empty dependency array means this runs once on mount

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={4}
        md={7}
        style={{
          backgroundImage: "url(" + finalImage + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className={styles.homeLogoContainer}>
          <img style={{ width: "150px" }} src={logo} alt="" />
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {children}
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default LoginWrapper;
