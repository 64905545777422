// All APIs related to Alerts system (RubiALERT)
import * as http from "./http";
import { APP_ALERT_SERVER_URL } from "../../environment";
import { BulletinResolutionRequest, IPoliceService, ITag } from "../../types";

export const AlertAPIUrl = APP_ALERT_SERVER_URL;

export const getPoliceServices = () => {
  const api = `${AlertAPIUrl}police_service`;
  return http
    .get(api, { credentials: "include" })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMailingLists = () => {
  const api = `${AlertAPIUrl}mailing_list`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};
export const getMailingListById = (id) => {
  const api = `${AlertAPIUrl}mailing_list/${id}`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const addMailingList = (name) => {
  const api = `${AlertAPIUrl}mailing_list`;
  const data = {
    name: name,
  };
  return http
    .put(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const addEmailToMailingList = (id, email: string) => {
  const api = `${AlertAPIUrl}mailing_list/${id}/insert_item`;
  const data = {
    email: email,
  };
  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const removeEmailFromMailingList = (id, email: string) => {
  const api = `${AlertAPIUrl}mailing_list/${id}/remove_item`;
  const data = {
    email: email,
  };
  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const DeleteMailingList = (id) => {
  const api = `${AlertAPIUrl}mailing_list/${id}`;
  return http.del(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const getBulletins = (
  start: number,
  amount: number | null = null,
  associated_crime: string | null = null,
  type: string | null = null,
  issuing_police_service: IPoliceService = {} as IPoliceService,
  creator_org_id: string | null = null,
  incidentStartDate: string | null = null,
  incidentEndDate: string | null = null,
  issuedStartDate: string | null = null,
  issuedEndDate: string | null = null,
  searchText: string | null = null,
  is_draft: boolean = false,
  creator_user_id: string | null = null,
  similarTo: number | null = null,
  bounds: string | null = null,
) => {
  const api = new URL(AlertAPIUrl);
  api.pathname = "bulletin";

  // let api = `${AlertAPIUrl}bulletin?start=${start}&amount=${amount}`;
  api.searchParams.set("start", start.toString());

  if (amount && amount > 0) {
    api.searchParams.set("amount", amount.toString());
  }
  if (associated_crime)
    api.searchParams.set("associated_crime", associated_crime);
  if (type) api.searchParams.set("type", type);
  if (issuing_police_service?.name)
    api.searchParams.set(
      "issuing_police_service",
      issuing_police_service.name.toString(),
    );
  if (creator_org_id) api.searchParams.set("creator_org_id", creator_org_id);
  if (incidentStartDate)
    api.searchParams.set("incident_time_start", incidentStartDate);
  if (incidentEndDate)
    api.searchParams.set("incident_time_end", incidentEndDate);
  if (issuedStartDate)
    api.searchParams.set("issued_time_start", issuedStartDate);
  if (issuedEndDate) api.searchParams.set("issued_time_end", issuedEndDate);
  if (searchText && searchText != "")
    api.searchParams.set("search_query", searchText);
  if (creator_user_id) api.searchParams.set("creator_user_id", creator_user_id);
  if (similarTo) api.searchParams.set("similar_to", similarTo.toString());
  if (bounds) api.searchParams.set("bounds", bounds);

  api.searchParams.set("is_draft", "" + is_draft);

  const lang =
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("language="))
      ?.split("=")[1] ?? "en";

  return http
    .get(api.toString(), {
      credentials: "include",
      headers: { "Accept-Language": lang },
    })
    .then((response) => {
      return response;
    });
};

export const getBulletinById = (id) => {
  const api = `${AlertAPIUrl}bulletin/${id}`;

  const lang =
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("language="))
      ?.split("=")[1] ?? "en";
  return http
    .get(api, { credentials: "include", headers: { "Accept-Language": lang } })

    .then((response) => {
      return response;
    });
};

export const getBulletinByToken = (token) => {
  const api = `${AlertAPIUrl}view/bulletin?access_token=${token}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const patchBulletin = (data, bulletinID) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}`;
  return http
    .patch(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const sendBulletin = (id) => {
  const api = `${AlertAPIUrl}bulletin/${id}/send`;
  return http.post(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const batchSendBulletins = async (
  bulletin_ids: number[],
  group_ids: number[],
  note: string,
  include_myself: boolean,
) => {
  const api = `${AlertAPIUrl}bulletin/batch_send`;
  const data = { bulletin_ids, group_ids, note, include_myself };

  return http
    .post(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const putNewBulletin = () => {
  const api = `${AlertAPIUrl}bulletin`;
  return http
    .put(api, { body: {}, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const deleteBulletin = (id) => {
  const api = `${AlertAPIUrl}bulletin/${id}`;
  return http.del(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const alertUploadPDF = (field, data, bulletinID) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/pdf`;
  const formData = new FormData();
  formData.append(field, data);
  const headers = new Headers();

  return http
    .post(api, {
      body: formData,
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const alertGetBulletinDetailsFromPDF = (field, data) => {
  const api = `${AlertAPIUrl}bulletin/text_from_pdf`;
  const formData = new FormData();
  formData.append(field, data);
  const headers = new Headers();

  return http
    .post(api, {
      body: formData,
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const alertGetBulletinImagesFromPDF = (field, data, bulletinID) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/images_from_pdf`;
  const formData = new FormData();
  formData.append(field, data);
  const headers = new Headers();

  return http
    .post(api, {
      body: formData,
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const alertDeletePDF = (bulletinID: number) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/pdf`;
  const headers = new Headers();

  return http
    .del(api, {
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const alertUploadFile = (field, data, bulletinID) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/attachment`;
  const formData = new FormData();
  formData.append(field, data);
  const headers = new Headers();

  return http
    .post(api, {
      body: formData,
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const alertDeleteFile = (fileName: string, bulletinID: string) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/attachment/${fileName}`;
  const headers = new Headers();
  return http
    .del(api, {
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const getMetabaseURL = (groupID: number, dashboard: number = 1) => {
  const api = `${AlertAPIUrl}user/metabase_view?dashboard=${dashboard}&group_id=${groupID}`;
  const headers = new Headers();

  return http
    .get(api, {
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const getUserMeGroups = () => {
  const api = `${AlertAPIUrl}user/me/groups`;
  const headers = new Headers();

  return http
    .get(api, {
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const getGroupMembers = (groupID) => {
  const api = `${AlertAPIUrl}group/${groupID}/members`;
  const headers = new Headers();

  return http
    .get(api, {
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const getNonMembers = (groupID) => {
  const api = `${AlertAPIUrl}group/${groupID}/non_members`;
  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const addUserToGroup = (groupID, userId) => {
  const api = `${AlertAPIUrl}group/${groupID}/add_member`;
  const data = {
    id: userId,
  };

  return http.post(api, {
    body: data,
    credentials: "include",
  });
};

export const removeUserFromGroup = (groupID, userId) => {
  const api = `${AlertAPIUrl}group/${groupID}/remove_member`;
  const data = {
    id: userId,
  };

  return http.post(api, {
    body: data,
    credentials: "include",
  });
};

export const shareBulletin = (add_groups, remove_groups, bulletinIds) => {
  const api = `${AlertAPIUrl}bulletin/bulk_update_groups`;
  const data = {
    add_groups,
    remove_groups,
    bulletinIds,
  };
  return http.post(api, { body: data, credentials: "include" });
};

export const addBulletinGroups = (bulletinID, groupsArray) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/add_groups`;
  const data = { groups: groupsArray };
  const headers = new Headers();

  return http
    .post(api, {
      body: data,
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const removeBulletinGroups = (bulletinID, groupsArray) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/remove_groups`;
  const data = { groups: groupsArray };
  const headers = new Headers();

  return http
    .post(api, {
      body: data,
      credentials: "include",
      headers,
    })
    .then((response) => {
      return response;
    });
};

export const getBulletinGroups = (bulletinID) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/groups`;

  return http.get(api, { credentials: "include" }).then((response) => {
    return response;
  });
};

export const addNewTag = (bulletinID: string, title: string) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/tag`;

  const data = {
    title: title,
  };

  return http
    .put(api, {
      body: data,
      credentials: "include",
    })
    .then((response) => {
      return response;
    });
};

export const removeBulletinTag = (bulletinID: string, tagID: string) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/tag/${tagID}`;

  return http
    .del(api, {
      credentials: "include",
    })
    .then((response) => {
      return response;
    });
};

export const patchTag = (bulletinID: number, tagID: string, tagData: ITag) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/tag/${tagID}`;

  return http
    .patch(api, { body: tagData, credentials: "include" })
    .then((response) => {
      return response;
    });
};

export const patchAttachmentSort = (
  bulletinID: number,
  filename: string,
  sortOrdinal: number,
) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/attachment/${filename}`;

  const data = {
    sort_ordinal: sortOrdinal,
  };

  return http
    .patch(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

// Give permission to a user

/**
 * Adds user profile to alert app
 * @param user_id
 * @returns
 */
export const putUser = (user_id: string) => {
  const api = `${AlertAPIUrl}user`;
  const data = {
    id: user_id,
  };
  return http
    .put(api, { body: data, credentials: "include" })
    .then((response) => {
      return response;
    });
};

// /**
//  * Grant user access to use the alert app
//  * @param user_id
//  * @returns
//  */
// export const giveAlertAccess = (user_id: string, access: boolean) => {
//   const api = `${AlertAPIUrl}user/${user_id}/alert_access`;
//   const body = {
//     access: access,
//   };
//   return http
//     .post(api, { body: body, credentials: "include" })
//     .then((response) => {
//       return response;
//     });
// };

// /**
//  * Revoke user access to use the alert app
//  * @param user_id
//  * @returns
//  */
// export const revokeAppAccess = (user_id: string) => {
//   const api = `${AlertAPIUrl}user/${user_id}/revoke_access`;
//   return http.post(api, { credentials: "include" }).then((response) => {
//     return response;
//   });
// };

/**
 * putBulletinResolution - Add a resolution to a bulletin
 * @param bulletinID
 * @param resolution
 * @returns
 */
export const putBulletinResolution = (
  bulletinID: number,
  resolution: BulletinResolutionRequest,
) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/resolution`;
  const response = http.put(api, {
    body: resolution,
    credentials: "include",
  });
  return response;
};

/**
 * patchBulletinResolution - Update a resolution
 * @param bulletinID
 * @param resolutionId
 * @param resolution
 * @returns
 */
export const patchBulletinResolution = (
  bulletinID: number,
  resolutionId: number,
  resolution: BulletinResolutionRequest,
) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/resolution/${resolutionId}`;
  const response = http.patch(api, {
    body: resolution,
    credentials: "include",
  });
  return response;
};

export const deleteBulletinResolution = (
  bulletinID: number,
  resolutionId: number,
) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinID}/resolution/${resolutionId}`;
  const response = http.del(api, {
    credentials: "include",
  });
  return response;
};

export const uploadResolutionAttachment = (
  bulletinId: number,
  resolutionId: number,
  data: Blob,
) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinId}/resolution/${resolutionId}/attachment`;
  const formData = new FormData();
  formData.append("file", data);

  const response = http.post(api, {
    body: formData,
    credentials: "include",
  });
  return response;
};

export const deleteResolutionAttachment = (
  bulletinId: number,
  resolutionId: number,
  filename: string,
) => {
  const api = `${AlertAPIUrl}bulletin/${bulletinId}/resolution/${resolutionId}/attachment/${filename}`;
  const response = http.del(api, {
    credentials: "include",
  });
  return response;
};

export const getPortalAccessGroupsByBulletinIds = (bulletinIds: number[]) => {
  const api = `${AlertAPIUrl}bulletin/portal_access_groups?bulletin_ids=${bulletinIds.join(",")}`;
  const response = http.get(api, {
    credentials: "include",
  });
  return response;
};
