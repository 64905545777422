import dayjs from "dayjs";
import i18n from "i18next";

import {
  LOCATION_CLASSIFICATION,
  LOCATION_CLASSIFICATION_REFERENCE,
  emptyContact,
} from "./constants";
import { serviceConfigurations } from "../../common/serviceConfigurations";
import {
  IAlertProperties,
  IContact,
  IPoliceService,
  BulletinResourceResponse,
  PlaceType,
} from "../../types";

export const getAddressClassification = (address) => {
  if (typeof address === "string") {
    return i18n.t(
      `bulletin/incident_location_type:${LOCATION_CLASSIFICATION_REFERENCE["Incident Address"]}`,
    );
  } else if (address === null) {
    return i18n.t(
      `bulletin/incident_location_type:${LOCATION_CLASSIFICATION_REFERENCE["Incident Address"]}`,
    );
  } else {
    if (address.location_classification === LOCATION_CLASSIFICATION.OTHER) {
      return address.other_location_classification;
    }
    return i18n.t(
      `bulletin/incident_location_type:${LOCATION_CLASSIFICATION_REFERENCE[address.location_classification]}`,
    );
  }
};

export const getAddressDescription = (address) => {
  if (typeof address === "string") {
    return address;
  } else if (address === null) {
    return [""];
  } else {
    return address.description;
  }
};

export function updateToContextStructure(
  alert: BulletinResourceResponse,
): IAlertProperties {
  const {
    issuing_police_service,
    type,
    associated_crime,
    occurrence_number,
    incident_times,
    description,
    location,
    tags,
    issued_time,
    contacts,
    evidence_files,
    operational_area,
    approved_by,
    approved_by_badge,
    classification,
    id,
    creator,
    is_draft,
    pdf,
    resolutions,
    description_fr,
    original_language,
  } = alert;
  const addresses: PlaceType[] = location?.addresses;

  return {
    policeService: issuing_police_service,
    type,
    associatedCrimes: associated_crime.map((crime) => ({
      crime,
      group: "",
    })),
    occurrenceNumbers: occurrence_number.map((occurrence) => occurrence),
    incidentTimes:
      incident_times.length > 0
        ? incident_times
        : [{ time_type: "exact", start_date: null, end_date: null }],
    description,
    descriptionFr: description_fr,
    originalLanguage: original_language,
    rawAddresses: addresses || [null],
    tagDict: tags,
    issuedTime: issued_time ? dayjs(issued_time) : null,
    bulletinId: id,
    operationalArea: operational_area ?? null,
    approvedBy: approved_by,
    approvedByBadge: approved_by_badge,
    classification: classification,
    contacts,
    creator: creator,
    evidence_files,
    isDraft: is_draft === 0 ? false : true,
    pdf: pdf,
    resolutions,
  };
}

export const isContactEmpty = (contact: IContact) => {
  return Object.keys(contact).every((key) => {
    return contact[key] === emptyContact[key];
  });
};

export const validateOperationalArea = (
  policeService: IPoliceService,
  operationalArea,
) => {
  if (
    serviceConfigurations[policeService.name] &&
    serviceConfigurations[policeService.name].operationalArea
  ) {
    return operationalArea ? false : true;
  } else {
    return false;
  }
};

export const validateContactName = (contacts: IContact[]) => {
  const contactNameErrors = contacts.map((contact) => {
    return contact.name === "" ? true : false;
  });
  if (contacts.length > 1) {
    const lastContact = contacts[contacts.length - 1];
    if (isContactEmpty(lastContact)) {
      contactNameErrors[contactNameErrors.length - 1] = false;
    }
  }
  return contactNameErrors;
};

export const validateGeocodedAddresses = async (addresses) => {
  const geocoder = new google.maps.Geocoder();
  const addressValidities = addresses.map(async (address) => {
    const hasLocationType = () => {
      const locationClassifications = Object.values(LOCATION_CLASSIFICATION);
      if (locationClassifications.includes(address?.location_classification)) {
        if (
          address.location_classification === LOCATION_CLASSIFICATION.OTHER &&
          address.other_location_classification &&
          address.other_location_classification !== ""
        ) {
          return true;
        } else if (
          address.location_classification !== LOCATION_CLASSIFICATION.OTHER
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    };
    return new Promise((resolve, reject) => {
      if (typeof address === "string") {
        resolve({ address: address, isValid: false, hasLocationType: false });
      }
      if (address?.place_id) {
        geocoder.geocode({ placeId: address.place_id }, (results, status) => {
          if (status === "OK") {
            resolve({
              address: address,
              isValid: true,
              hasLocationType: hasLocationType(),
            });
          } else {
            resolve({
              address: address,
              isValid: false,
              hasLocationType: hasLocationType(),
            });
          }
        });
      } else {
        resolve({
          address: address,
          isValid: false,
          hasLocationType: hasLocationType(),
        });
      }
    });
  });

  const results = await Promise.all(addressValidities);
  return results;
};
