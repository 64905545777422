import { Delete } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { SortableAttachments } from "./SortableAttachments";
import styles from "./UploadStep.module.css";
import LoadingModal from "../../../../common/LoadingModal";
import TriUploadFile from "../../../../common/TriUploadFile";
import { apiAlertClient } from "../../../../common/api-client";
import upload from "../../../../images/upload.png";
import { AlertMode } from "../../../../types";
import "../../alerts.scss";
import { SingleAlertContext } from "../../context";
import { ImageEditor } from "../ImageEditor";
import { ManageTags } from "../ManageTags";

interface UploadStepProps {
  bulletinID: number;
  mode: AlertMode;
  imagesLoading: boolean;
}

export const UploadStep = ({ mode, ...props }: UploadStepProps) => {
  const [isPdfUploading, setIsPDFUploading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [shouldCancel, setShouldCancel] = useState(false);
  const shouldCancelRef = useRef(shouldCancel);
  const [editMediaObject, setEditMediaObject] = useState(null);
  const { setAlertProperty, alertProperties } = useContext(SingleAlertContext);
  const { evidence_files, pdf } = alertProperties;
  const { t } = useTranslation("bulletin/create/upload");
  const pdfInput = useRef<HTMLInputElement>(null);
  const imageInput = useRef<HTMLInputElement>(null);

  const handlePdfUpload = (event) => {
    const ext = event.target.value.match(/\.([^.]+)$/)[1];
    switch (ext) {
      case "pdf":
        break;
      default:
        toast.error(t("toast.error.fileTypeNotAllowed"));
        return;
    }
    setIsPDFUploading(true);
    apiAlertClient
      .alertUploadPDF("file", event.target.files[0], props.bulletinID)
      .then((res) => {
        setAlertProperty("pdf", res.pdf);
        setIsPDFUploading(false);
      })
      .catch((err) => {
        toast.error(t("toast.error.errorUploadingPDF"));
        setIsPDFUploading(false);
      });
  };

  useEffect(() => {
    shouldCancelRef.current = shouldCancel;
  });

  const handleImageUpload = async (event, files) => {
    setIsImageUploading(true);
    // TODO: Add a check for the file type

    for (let index = 0; index < files.length; index++) {
      try {
        const res = await apiAlertClient.alertUploadFile(
          "file",
          files[index],
          props.bulletinID,
        );

        if (shouldCancelRef.current) {
          // Cancelled uploading, break the loop
          handleFileDelete(files[index].name, props.bulletinID.toString());
          break;
        }

        if (res) {
          setAlertProperty("evidence_files", {
            ...res.evidence_files,
          });
        } else {
          toast.error(t("toast.error.errorUploadingImage"));
          break;
        }
      } catch (err) {
        toast.error(t("toast.error.errorUploadingImage"));
        break;
      }
    }
    setShouldCancel(false);
    setIsImageUploading(false);

    if (event.target) {
      event.target.value = null;
    }
  };

  const handleFileDelete = (fileName: string, bulletinID: string) => {
    setIsImageUploading(true);
    // Delete from server
    apiAlertClient
      .alertDeleteFile(fileName, bulletinID)
      .then((res) => {
        // Delete from local state
        if (res) {
          setAlertProperty("evidence_files", {
            ...res.evidence_files,
          });
        }
        setIsImageUploading(false);
      })
      .catch((err) => {
        setIsImageUploading(false);
        toast.error(t("toast.error.errorDeletingFile"), err);
      });
  };

  const handleFileReupload = (
    fileName: string,
    bulletinID: number,
    file: File,
  ) => {
    setIsImageUploading(true);

    setAlertProperty("evidence_files", {});
    apiAlertClient
      .alertUploadFile("file", file, bulletinID)
      .then((res) => {
        // Update local state
        if (res) {
          setAlertProperty("evidence_files", {
            ...res.evidence_files,
          });
        }
        setIsImageUploading(false);
      })
      .catch((err) => {
        setIsImageUploading(false);
        toast.error(t("toast.error.errorDeletingFile"), err);
      });
    setEditMediaObject(null);
  };

  return (
    <div style={{ width: "100%" }}>
      {!("filename" in pdf) && (
        <div
          className={styles.alertUploadContainer}
          onClick={() => {
            if (pdfInput.current) {
              pdfInput.current.click();
            }
          }}
        >
          <div className={styles.uploadBtn}>
            <img src={upload} className={styles.uploadBtnImage} alt="" />
            <div className={styles.uploadBtnCaption}>
              {t("uploadBtnCaption")} <br />
              <span>{t("option")}</span>
            </div>
          </div>

          {isPdfUploading && (
            <CircularProgress size="20px" thickness={5} color="secondary" />
          )}
          <input
            ref={pdfInput}
            onChange={handlePdfUpload}
            type="file"
            accept=".pdf"
            style={{ display: "none" }}
          />
        </div>
      )}
      {"filename" in pdf && (
        <div className={styles.alertUploadContainer}>
          <div className={styles.uploadBtnCaption}>BULLETIN</div>
          <div className={styles.uploadedPdfParent}>
            <div className={styles.uploadedPdfName}>{pdf.filename}</div>
            <Tooltip title={t("deleteBtnCaption")} placement="top">
              <IconButton
                onClick={(e) => {
                  apiAlertClient.alertDeletePDF(props.bulletinID);
                  setAlertProperty("pdf", {});
                  e.stopPropagation();
                }}
                color="primary"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={styles.alertUploadContainer}>
        {!isImageUploading && (
          <TriUploadFile
            fileInput={imageInput}
            handleFileUpload={handleImageUpload}
            accept="image/*,video/*"
            multiple
          />
        )}
        {isImageUploading && (
          <>
            <CircularProgress size="20px" thickness={5} color="secondary" />
            <Button
              variant="outlined"
              onClick={() => {
                setShouldCancel(true);
                setIsImageUploading(false);
              }}
            >
              {t("cancel")}
            </Button>
          </>
        )}
      </div>

      {mode === "upload" && props.imagesLoading ? (
        <div
          style={{
            padding: "36px",
          }}
        >
          <LoadingModal
            stages={[
              t("stage.extractingImages"),
              t("stage.uploadingImages"),
              t("stage.processingImages"),
              t("stage.generatingSmartSearchTags"),
              t("stage.wrappingUp"),
            ]}
            timingMessage={t("stage.timingMessage")}
          />
        </div>
      ) : null}

      {Object.keys(evidence_files).length > 0 && (
        <SortableAttachments
          handleFileDelete={handleFileDelete}
          bulletinID={props.bulletinID}
          attachments={evidence_files}
          setAttachments={setAlertProperty}
          setEditMediaObject={setEditMediaObject}
        />
      )}
      {editMediaObject && (
        <ImageEditor
          imageObject={editMediaObject}
          handleImageUpload={handleFileReupload}
          onCancel={() => {
            console.log("Cancel Image Editing");
            setEditMediaObject(null);
          }}
          bulletinID={props.bulletinID}
        />
      )}
      <div className={styles.tagsContainer}>
        <ManageTags bulletinID={props.bulletinID} />
      </div>
    </div>
  );
};
