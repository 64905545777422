import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import contactInfoBoxEN from "./locales/en/bulletin/create/contactInfoBox.json";
import datePickerEN from "./locales/en/bulletin/create/datePicker.json";
import createEN from "./locales/en/bulletin/create/index.json";
import uploadEN from "./locales/en/bulletin/create/upload.json";
import filterEN from "./locales/en/bulletin/filter.json";
import menuEN from "./locales/en/bulletin/menu.json";
import searchbarEN from "./locales/en/bulletin/searchbar.json";
import shareBulletinEN from "./locales/en/bulletin/share.json";
import classificationEN from "./locales/en/bulletin/static/classification.json";
import crimeEN from "./locales/en/bulletin/static/crime.json";
import incidentLocationTypeEN from "./locales/en/bulletin/static/incident_location_type.json";
import incidentTypeEN from "./locales/en/bulletin/static/incident_type.json";
import bulletinEN from "./locales/en/bulletin.json";
import condensedReportEN from "./locales/en/reportsv2/condensed_report.json";
import contactInfoBoxFR from "./locales/fr/bulletin/create/contactInfoBox.json";
import datePickerFR from "./locales/fr/bulletin/create/datePicker.json";
import createFR from "./locales/fr/bulletin/create/index.json";
import uploadFR from "./locales/fr/bulletin/create/upload.json";
import filterFR from "./locales/fr/bulletin/filter.json";
import menuFR from "./locales/fr/bulletin/menu.json";
import searchbarFR from "./locales/fr/bulletin/searchbar.json";
import shareBulletinFR from "./locales/fr/bulletin/share.json";
import classificationFR from "./locales/fr/bulletin/static/classification.json";
import crimeFR from "./locales/fr/bulletin/static/crime.json";
import incidentLocationTypeFR from "./locales/fr/bulletin/static/incident_location_type.json";
import incidentTypeFR from "./locales/fr/bulletin/static/incident_type.json";
import bulletinFR from "./locales/fr/bulletin.json";
import condensedReportFR from "./locales/fr/reportsv2/condensed_report.json";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      bulletin: bulletinEN,
      "bulletin/searchbar": searchbarEN,
      "bulletin/share": shareBulletinEN,
      "bulletin/filter": filterEN,
      "bulletin/menu": menuEN,
      "bulletin/create": createEN,
      "bulletin/create/upload": uploadEN,
      "bulletin/create/datePicker": datePickerEN,
      "bulletin/create/contactInfoBox": contactInfoBoxEN,
      "bulletin/bulletinType": incidentTypeEN,
      "bulletin/incident_location_type": incidentLocationTypeEN,

      "bulletin/classification": classificationEN,

      "bulletin/associatedCrime": crimeEN,
      "reportsv2/condensed_report": condensedReportEN,
    },
    fr: {
      bulletin: bulletinFR,

      "bulletin/searchbar": searchbarFR,
      "bulletin/share": shareBulletinFR,
      "bulletin/filter": filterFR,
      "bulletin/menu": menuFR,
      "bulletin/create": createFR,
      "bulletin/create/upload": uploadFR,
      "bulletin/create/contactInfoBox": contactInfoBoxFR,
      "bulletin/create/datePicker": datePickerFR,

      "bulletin/bulletinType": incidentTypeFR,
      "bulletin/incident_location_type": incidentLocationTypeFR,

      "bulletin/classification": classificationFR,
      "bulletin/associatedCrime": crimeFR,
      "reportsv2/condensed_report": condensedReportFR,
    },
  },

  lng: "en",

  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
