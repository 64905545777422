import { Close } from "@mui/icons-material";
import { Box, Button, Modal } from "@mui/material";
import "./RubiModal.scss";

const RubiModal = ({
  isModalOpenArg,
  setIsModalOpenArg,
  children,
  onClose,
}: {
  isModalOpenArg: boolean;
  setIsModalOpenArg: (arg: boolean) => void;
  children: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <Modal open={isModalOpenArg} className="rubi-modal">
      <Box className="page-modal">
        <Button
          onClick={() => {
            setIsModalOpenArg(false);
            onClose();
          }}
          className="close-button"
          variant="contained"
          color="primary"
          size="medium"
        >
          <Close />
        </Button>
        <div className="modal-content">{children}</div>
      </Box>
    </Modal>
  );
};

const RubiModalWithNoCloseButton = ({
  isModalOpenArg,
  children,
}: {
  isModalOpenArg: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Modal open={isModalOpenArg}>
      <Box>
        <div className="modal-content">{children}</div>
      </Box>
    </Modal>
  );
};

export { RubiModal, RubiModalWithNoCloseButton };
