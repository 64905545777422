import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import { defaultTheme } from "./constants";
import { OnlineReportingContext } from "./context";
import "survey-core/defaultV2.min.css";
import "./ViewReport.css";
import { registerAutocomplete } from "./custom-questions/AddressAutocompleteQuestion";
import { loadImageData } from "./utils";
import { useDevEnvironment } from "../../DevContext";
import { SubmissionAttachment } from "../../common/api-client/or-api";
import { API } from "../../common/api-client/or-api/api";
import { getServiceConfig } from "../../common/serviceConfigurations";
import { AuthStateContext } from "../../context";

const ViewReport = ({ editing = false }: { editing: boolean }) => {
  const navigate = useNavigate();
  const appName = window.location.pathname.split("/")[1];
  const [report, setReport] = useState<{ [key: string]: any }>({});
  const [survey, setSurvey] = useState<Model>(new Model());
  const [attachmentIDs, setAttachmentIDs] = useState<{ [key: string]: string }>(
    {},
  );

  const { id, orgId } = useParams();
  const { getSubmissionForID, getSurveyForID } = useContext(
    OnlineReportingContext,
  );
  const { currentUser } = useContext(AuthStateContext);
  const { isDevEnv } = useDevEnvironment();

  const organizationConfig = getServiceConfig(
    orgId ?? currentUser?.rubi_org_id ?? "NA",
    isDevEnv,
  );

  const submitForm = useCallback(
    async (sender: any, options: any) => {
      options.showSaveInProgress();
      try {
        await API.submission.updateStatusPortal(id ?? "", {
          status: "modification_portal",
        });

        const dataToSend = JSON.parse(JSON.stringify(sender.data));
        const traverse = (obj: any) => {
          for (const key in obj) {
            if (key === "content" && obj["content"]?.startsWith("data:")) {
              delete obj[key];
            } else if (typeof obj[key] === "object") {
              traverse(obj[key]);
            }
          }
        };
        traverse(dataToSend);
        await API.submission.patchPortal(id ?? "", {
          version: {
            submission: dataToSend,
            attachments: { ids: Object.values(attachmentIDs) },
          },
        });
        await API.submission.updateStatusPortal(id ?? "", {
          status: "submitted",
        });

        options.showSaveSuccess();
        navigate(`/${appName}/report/${id}/condensed`, { replace: true });
      } catch (error) {
        console.error(error);
        await API.submission.updateStatusPortal(id ?? "", {
          status: "submitted",
        });
        options.showSaveError();
      }
    },
    [id, attachmentIDs],
  );

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const uploadFiles = useCallback(
    async (options: any) => {
      const returnValues: { [key: string]: any } = [];
      for (const file of options.files) {
        console.log(file.name);
        const s3Details = await API.submission.postSubmissionAttachmentPortal(
          id ?? "",
          {
            filename: file.name,
            size: file.size,
            content_type: file.type,
            version_specific: false,
          },
        );
        const headers: { [key: string]: string } = {};
        s3Details.s3_presigned.headers.forEach((header) => {
          headers[header.key] = header.value;
        });
        await axios.put(s3Details.s3_presigned.uri, file, {
          headers,
        });
        setAttachmentIDs({
          ...attachmentIDs,
          [options.name + file.name]: s3Details.id,
        });

        if (organizationConfig?.generateAttachmentDescriptions) {
          const { description } =
            await API.submission.getSubmissionAttachmentDescriptionRequestPortal(
              id ?? "",
              s3Details.id,
            );
          const descriptionsElement = options.question.parent.elements[1];
          try {
            const descriptionJSON = JSON.parse(description);
            let newDescription = "";
            for (const key in descriptionJSON) {
              newDescription += `${key}: ${descriptionJSON[key].join(", ")}\n\n`;
            }
            descriptionsElement.value = newDescription;
          } catch (error) {
            console.error("Error while setting attachment description", error);
            descriptionsElement.value =
              "Unable to generate description tags, please add your own.";
          }
        }

        returnValues.push({
          id: s3Details.id,
          file: file,
          content: await toBase64(file),
        });
      }
      console.log("callback");
      options.callback(returnValues);
    },
    [attachmentIDs],
  );

  const clearFiles = useCallback(
    async (options: any) => {
      console.log(options);
      if (!options.value || options.value.length === 0)
        return options.callback("success");
      if (!options.fileName && !!options.value) {
        for (const item of options.value) {
          const newIDs = attachmentIDs;
          delete newIDs[options.name + item.name];
          setAttachmentIDs(newIDs);
          return options.callback("success");
        }
      } else {
        const fileToRemove = options.value.find(
          (item: File) => item.name === options.fileName,
        );
        if (fileToRemove) {
          const newIDs = attachmentIDs;
          delete newIDs[options.name + fileToRemove.name];
          setAttachmentIDs(newIDs);
          return options.callback("success");
        } else {
          console.error(`File with name ${options.fileName} is not found`);
        }
      }
    },
    [attachmentIDs],
  );

  useEffect(() => {
    survey?.onComplete.clear();
    survey?.onComplete.add((sender, options) => submitForm(sender, options));
    survey?.onUploadFiles.clear();
    survey?.onUploadFiles.add((_, options) => uploadFiles(options));

    survey?.onClearFiles.clear();
    survey?.onClearFiles.add((_, options) => clearFiles(options));

    survey?.onCurrentPageChanged.clear();
    survey?.onCurrentPageChanged.add(() => {
      window.scrollTo(0, 0);
    });
  }, [survey, attachmentIDs]);

  useEffect(() => {
    async function getReportData() {
      if (getSubmissionForID && getSurveyForID) {
        const result = await getSubmissionForID(id ?? "");
        const surveyResult = await getSurveyForID(result?.survey_id ?? "");
        const submission = result?.submission;
        await loadImageData(submission, result?.attachments ?? [], true);
        setReport(submission);
        const newSurvey = new Model(surveyResult?.survey);
        newSurvey.data = submission;

        const newAttachments: { [key: string]: string } = {};
        result?.attachments.forEach((attachment: SubmissionAttachment) => {
          newAttachments[`${attachment.filename}-${attachment.id}`] =
            attachment.id;
        });
        setAttachmentIDs(newAttachments);
        newSurvey.applyTheme(defaultTheme);

        if (!editing) {
          newSurvey.showPreview();
          newSurvey.showNavigationButtons = false;
        }
        setSurvey(newSurvey);
      }
    }
    registerAutocomplete();
    getReportData();
  }, []);

  return (
    <>
      <Survey model={survey} />
    </>
  );
};

export default ViewReport;
