import TextField from "@mui/material/TextField";
import "../alerts.scss";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ContactInfoBox.module.css";
import { colors } from "../../../common/colors";
import {
  IPoliceServiceConfiguration,
  serviceConfigurations,
  substituteFormatValue,
} from "../../../common/serviceConfigurations";
import { formatPhoneNumber, validateEmail } from "../../../common/utils";
import { emptyContact } from "../constants";
import { SingleAlertContext } from "../context";
import { isContactEmpty } from "../utils";

interface IContactInfoBoxProps {
  contacts: any;
  setContacts: any;
  policeService: any;
}

export const ContactInfoBox = ({
  contacts,
  setContacts,
  policeService,
}: IContactInfoBoxProps) => {
  const serviceConfiguration: IPoliceServiceConfiguration = policeService?.name
    ? serviceConfigurations[policeService?.name]
    : ({} as IPoliceServiceConfiguration);
  const fieldConfigurations = serviceConfiguration?.fieldConfigurations ?? {};
  const { errors, setAlertProperty } = useContext(SingleAlertContext);
  const { t } = useTranslation("bulletin/create/contactInfoBox");
  const updateContact = (ev, index) => {
    const contactsArr = [...contacts];
    const contact = { ...contactsArr[index] };

    contact[ev.target.name] = ev.target.value;
    contact.error = false;

    if (ev.target.name === "email") {
      if (!validateEmail(ev.target.value)) contact.error = true;
    }

    if (ev.target.name === "phone_number") {
      contact[ev.target.name] = formatPhoneNumber(ev.target.value);
    }

    if (fieldConfigurations[`contact.${ev.target.name}`]) {
      const fieldConfiguration =
        fieldConfigurations[`contact.${ev.target.name}`];
      console.info(fieldConfiguration);
      if (fieldConfiguration.formatRegex)
        console.info(
          new RegExp(fieldConfiguration.formatRegex).test(ev.target.value),
        );
      if (
        fieldConfiguration.formatRegex &&
        !new RegExp(fieldConfiguration.formatRegex).test(ev.target.value)
      ) {
        contact.error = true;
      }

      if (fieldConfiguration.events) {
        const changeEvent = fieldConfiguration.events.find(
          (event) => event.eventName === "onChange",
        );

        if (changeEvent) {
          contact[changeEvent.fieldToUpdate] = substituteFormatValue(
            changeEvent.newValue,
            contact,
          );
        }
      }
    }

    contactsArr[index] = contact;
    if (contactsArr.some((contact) => isContactEmpty(contact))) {
      contactsArr.splice(
        contactsArr.findIndex((contact) => isContactEmpty(contact)),
        1,
      );
    }
    if (!isContactEmpty(contactsArr[contactsArr.length - 1])) {
      contactsArr.push(emptyContact);
    }
    setContacts(contactsArr);
  };
  if (contacts.length === 0) {
    contacts.push(emptyContact);
    setAlertProperty("contacts", contacts);
  }

  const helperTextStyling = {
    color: colors.ERROR_TEXT_RED,
    marginLeft: "10px",
  };

  const errorMessages = {
    contactName: "Contact Name is required",
    contactEmail: "Invalid Email",
    contactPhone: "Invalid Phone Number",
  };

  return (
    <div>
      <div className={styles.sectionHeadingContainer}>
        {t("section_heading")}
      </div>

      {contacts?.map((item, index) => {
        let emailErr = false;
        let phoneErr = false;
        if (index === 0) {
          emailErr = errors?.contactEmail;
          phoneErr = errors?.contactPhone;
        }
        const formattedPhoneNumber = formatPhoneNumber(item.phone_number);
        return (
          <div key={index} className={styles.containerContacts}>
            {index !== 0 && <div className={styles.divider}></div>}
            <div className={styles.containerContactInfo}>
              <div className={styles.containerName}>
                <TextField
                  label={t("contact_name", {
                    index: index + 1,
                  })}
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={errors?.contactName?.[index]}
                  className="text-input-mine"
                  name="name"
                  placeholder="e.g. Cst. Jane Smith"
                  value={item.name}
                  onChange={(ev) => {
                    updateContact(ev, index);
                  }}
                  helperText={
                    errors?.contactName?.[index] && (
                      <span style={helperTextStyling}>
                        {errorMessages.contactName}
                      </span>
                    )
                  }
                  FormHelperTextProps={helperTextStyling}
                />
              </div>
              <div className={styles.containerBadge}>
                <TextField
                  label={t("badge_number")}
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className="text-input-mine"
                  name="badge_number"
                  value={item.badge_number}
                  onChange={(ev) => {
                    updateContact(ev, index);
                  }}
                />
              </div>
            </div>
            <div className={styles.containerContactInfo}>
              <div className={styles.containerEmail}>
                <TextField
                  label={t("email")}
                  type="email"
                  variant="outlined"
                  fullWidth
                  className="text-input-mine"
                  name="email"
                  size="small"
                  error={item.error || emailErr}
                  value={item.email}
                  onChange={(ev) => {
                    updateContact(ev, index);
                  }}
                  helperText={
                    (item.error || emailErr) && (
                      <span style={helperTextStyling}>
                        {errorMessages.contactEmail}
                      </span>
                    )
                  }
                  FormHelperTextProps={helperTextStyling}
                />
              </div>
              <div className={styles.containerPhoneExt}>
                <div className={styles.containerPhone}>
                  <TextField
                    label={t("phone_number")}
                    type="text"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className="text-input-mine"
                    name="phone_number"
                    value={formattedPhoneNumber}
                    onChange={(ev) => {
                      updateContact(ev, index);
                    }}
                    error={item.error || phoneErr}
                    helperText={
                      (item.error || phoneErr) && (
                        <span style={helperTextStyling}>
                          {errorMessages.contactPhone}
                        </span>
                      )
                    }
                  />
                </div>
                <div className={styles.containerExt}>
                  <TextField
                    label={t("extension")}
                    type="text"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className="text-input-mine"
                    name="extension"
                    value={item.extension}
                    onChange={(ev) => {
                      updateContact(ev, index);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
