import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from "@lexical/markdown";
import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { EditorState, LexicalEditor as LexicalEditorType } from "lexical";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useFormBuilderContext } from "./FormContextProvider";
import { extractSurveyTitleFromModel } from "./utils";
import { useDevEnvironment } from "../../../DevContext";
import LexicalEditor, {
  customTransformers,
} from "../../../common/LexicalEditor";
import { SurveyConfiguration } from "../../../common/api-client/or-api";
import { API } from "../../../common/api-client/or-api/api";
import { getServiceConfig } from "../../../common/serviceConfigurations";
import { AuthStateContext } from "../../../context";

import "./SurveyConfigurationEdit.scss";

const REPORT_ICONS = [
  "/report-icons/bicycle_registration_v2.png",
  "/report-icons/bicycle_registration.png",
  "/report-icons/break_and_enter.png",
  "/report-icons/camera_registry.png",
  "/report-icons/damage_property.png",
  "/report-icons/damage_vehicle.png",
  "/report-icons/fraud.png",
  "/report-icons/drug_search_request.png",
  "/report-icons/graffiti.png",
  "/report-icons/drug_seizure_report.png",
  "/report-icons/gas_station_driveoff.png",
  "/report-icons/harassing_communication.png",
  "/report-icons/keyholder_registration.png",
  "/report-icons/long_term_care_incident.png",
  "/report-icons/lost_item.png",
  "/report-icons/lost_license_plate.png",
  "/report-icons/school_incident.png",
  "/report-icons/shoplifting.png",
  "/report-icons/supplementary.png",
  "/report-icons/theft.png",
  "/report-icons/theft_from_vehicle.png",
  "/report-icons/traffic_violaiton.png",
  "/report-icons/trespassing.png",
  "/report-icons/vuln_persons_registry.png",
  "/report-icons/hate.png",
  "/report-icons/missing_id.png",
  "/report-icons/suspicious_drug_activity.png",
  "/report-icons/bicycle_theft.png",
];

export const SurveyConfigurationEdit = () => {
  const emptyConfig = {
    id: "",
    name: "",
    survey_id: "",
    survey_class: "",
    description: {
      definition: "",
      image_url: "",
    },
    hidden: false,
    is_supplemental: false,
    is_registry: false,
  };
  const { orgId, surveyClass } = useParams();
  const navigate = useNavigate();
  const isNewConfig = window.location.pathname.includes("/new");

  const { surveyConfigs, surveyModels } = useFormBuilderContext();
  const [config, setConfig] = useState<SurveyConfiguration>(emptyConfig);
  console.log(config.description.definition);
  const [originalConfig, setOriginalConfig] =
    useState<SurveyConfiguration | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  const { isDevEnv } = useDevEnvironment();
  const { currentUser } = useContext(AuthStateContext);
  const organizationConfig = getServiceConfig(
    orgId ?? currentUser?.rubi_org_id ?? "NA",
    isDevEnv,
  );

  const editorRef = useRef<any>(null);
  const editor: LexicalEditorType = editorRef.current;

  useEffect(() => {
    if (editor) {
      const currentState: EditorState = editor.getEditorState();
      const markdownString = currentState.read(() =>
        $convertToMarkdownString(customTransformers, undefined, true),
      );
      if (config.description.definition !== markdownString) {
        editor.update(() => {
          $convertFromMarkdownString(
            markdownString,
            customTransformers,
            undefined,
            true,
          );
        });
      }

      editor.registerUpdateListener(() => {
        const currentState = editor.getEditorState();
        const markdownString = currentState.read(() =>
          $convertToMarkdownString(customTransformers, undefined, true),
        );

        setConfig((prev) => ({
          ...prev,
          description: { ...prev.description, definition: markdownString },
        }));
        setIsDirty(true);
      });
    }
  }, [editor?.getEditorState()]);

  useEffect(() => {
    const fetchConfig = async () => {
      if (isNewConfig) {
        setConfig(emptyConfig);
        setOriginalConfig(emptyConfig);
        return;
      }

      try {
        const response = await API.orgConfig.getSurveyConfig(
          orgId!,
          surveyClass!,
        );
        setConfig(response);
        setOriginalConfig(response);
      } catch (error) {
        console.error(error);
        // navigate("/");
      }
    };
    fetchConfig();
  }, [orgId, surveyClass]);

  const handleChange =
    (field: keyof SurveyConfiguration) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      setConfig((prev) => (prev ? { ...prev, [field]: value } : emptyConfig));
      setIsDirty(true);
    };

  const handleSave = async () => {
    // Check for validation errors
    if (isDuplicateSurveyClass(config.survey_class)) {
      return;
    }

    try {
      await API.portal.postUpdateSurvey(orgId!, config!);
      setOriginalConfig(config);
      setIsDirty(false);
    } catch (error) {
      console.error(error);
    }
  };

  const isDuplicateSurveyClass = (surveyClass: string) => {
    return surveyConfigs.some(
      (config) =>
        config.survey_class === surveyClass && config.id !== originalConfig?.id,
    );
  };

  // Add this new function to get available survey IDs
  const getAvailableSurveyIds = () => {
    // Get all survey IDs from models that aren't used yet, plus current config's ID if editing
    const availableIds = surveyModels
      .filter(
        (model) => model.status === "draft" || model.id === config.survey_id,
      )
      .map((model) => ({
        id: model.id,
        name: extractSurveyTitleFromModel(model),
      }));

    return availableIds;
  };

  // if (!config) return null;

  return (
    <Paper className="survey-config-edit">
      <Typography variant="h4" gutterBottom>
        {isNewConfig
          ? "Create New Survey Configuration"
          : "Edit Survey Configuration"}
      </Typography>

      {originalConfig && (
        <div style={{ marginBottom: "2rem" }}>
          <Typography variant="subtitle1" gutterBottom>
            Access URL:
          </Typography>
          <a
            href={`https://${isDevEnv ? "test." : ""}services.tryrubicon.com/${organizationConfig.servicesURLSlug}/online-reports/report/${originalConfig.survey_class}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ wordBreak: "break-all" }}
          >
            {`https://${isDevEnv ? "test." : ""}services.tryrubicon.com/${organizationConfig.servicesURLSlug}/online-reports/report/${originalConfig.survey_class}`}
          </a>
        </div>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            value={config.name}
            onChange={handleChange("name")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Survey ID"
            value={config.survey_id}
            onChange={(e) => {
              setConfig((prev) => ({ ...prev, survey_id: e.target.value }));
              setIsDirty(true);
            }}
          >
            {getAvailableSurveyIds().map((survey) => (
              <MenuItem key={survey.id} value={survey.id}>
                {survey.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Survey Class"
            value={config.survey_class}
            onChange={handleChange("survey_class")}
            helperText={
              isDuplicateSurveyClass(config.survey_class)
                ? "This survey class already exists. Please choose a unique value."
                : "This uniquely defines the type of report you want to collect. It is included as part of the link"
            }
            error={isDuplicateSurveyClass(config.survey_class)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Description</Typography>
          <LexicalEditor
            // markdown={config.description.definition}
            editorRef={editorRef}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Configuration Icon"
            value={config.description.image_url}
            onChange={(e) => {
              setConfig((prev) => ({
                ...prev,
                description: { ...prev.description, image_url: e.target.value },
              }));
              setIsDirty(true);
            }}
          >
            {REPORT_ICONS.map((iconPath) => (
              <MenuItem key={iconPath} value={iconPath}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <img
                    src={iconPath}
                    alt="icon preview"
                    style={{ width: "24px", height: "24px" }}
                  />
                  {iconPath.split("/").pop()}
                </div>
              </MenuItem>
            ))}
          </TextField>
          {config.description.image_url && (
            <div style={{ marginTop: "1rem" }}>
              <img
                src={config.description.image_url}
                alt="Configuration Icon"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={config.is_supplemental}
                onChange={handleChange("is_supplemental")}
              />
            }
            label={
              <>
                Supplemental
                <Typography variant="caption" display="block" sx={{ ml: 0 }}>
                  Turn this on if you want the report type to be defined as a
                  supplemental, to be linked to other records
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={config.hidden}
                onChange={handleChange("hidden")}
              />
            }
            label={
              <>
                Hidden
                <Typography variant="caption" display="block" sx={{ ml: 0 }}>
                  Turn this on to not list the report type. You can still access
                  it directly via the link. E.g. you want to create special
                  reports for your community partners
                </Typography>
              </>
            }
          />
        </Grid>
      </Grid>

      <div className="actions">
        <Button
          variant="outlined"
          onClick={() => navigate(`/rubireportv2/${orgId}/forms`)}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!isDirty || isDuplicateSurveyClass(config.survey_class)}
        >
          Save Changes
        </Button>
      </div>
    </Paper>
  );
};
