import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  DateRangePickerCustom,
  FilterChip,
  RenderFilterChips,
  SearchBarFilters,
} from "./SearchFilters";
import { AuthStateContext } from "../../../context";
import { BULLETIN_TYPE, crimeGroupedTypes } from "../constants";
import { AlertViewContext } from "../context";

export const alertFilterConfig = (
  searchParams: URLSearchParams,
  view: string,
) => [
  {
    title: "My Bulletins",
    urlParam: "myAlerts",
    value: searchParams.get("myAlerts"),
    key: "myAlerts",
    displayFullLabel: false,
    customChipDisplay: (value) => (value === "true" ? "My Bulletins" : ""),
  },
  {
    title: "My Agency Bulletins",
    urlParam: "creator_org_id",
    key: "myAgencyBulletins",
    value: searchParams.get("creator_org_id"),
    displayFullLabel: false,
    customChipDisplay: (value) => (value ? "My Agency Bulletins" : ""),
  },
  {
    title: "Incident Start Date",
    key: "incidentStartDate",
    urlParam: "incidentStartDate",
    value: searchParams.get("incidentStartDate"),
    displayFullLabel: true,
  },
  {
    title: "Incident End Date",
    key: "incidentEndDate",
    urlParam: "incidentEndDate",
    value: searchParams.get("incidentEndDate"),
    displayFullLabel: true,
  },
  {
    title: "Issued Start Date",
    key: "issuedStartDate",
    urlParam: "issuedStartDate",
    value: searchParams.get("issuedStartDate"),
    displayFullLabel: true,
  },
  {
    title: "Issued End Date",
    key: "issuedEndDate",
    urlParam: "issuedEndDate",
    value: searchParams.get("issuedEndDate"),
    displayFullLabel: true,
  },

  {
    title: "Bulletin Type",
    key: "bulletinType",
    urlParam: "bulletinType",
    value: searchParams.get("bulletinType"),
    displayFullLabel: true,
  },
  {
    title: "Associated Crime",
    key: "associatedCrime",
    urlParam: "associatedCrime",
    value: searchParams.get("associatedCrime"),
    displayFullLabel: true,
  },

  {
    title: "Police Service",
    key: "policeService",
    urlParam: "policeService",
    value: searchParams.get("policeService"),
    displayFullLabel: true,
  },

  {
    title: "Showing Similar",
    key: "similarTo",
    urlParam: "similarTo",
    value: searchParams.get("similarTo"),
    displayFullLabel: true,
  },
  {
    title: "Geo Boundary",
    key: "bounds",
    urlParam: "bounds",
    value: searchParams.get("bounds"),
    displayFullLabel: false,
    customChipDisplay: (value) => {
      // const [swlat, swlng, nelat, nelng] = value.split(",");
      // return `SW: ${Math.round(swlat)}, ${Math.round(swlng)} NE: ${Math.round(nelat)}, ${Math.round(nelng)}`;
      return "Geo Boundary";
    },
    disableRemove: view === "map",
  },
];

export const RenderAlertFilterChips = ({
  searchParams,
  setSearchParams,
  getFilters,
}: {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: URLSearchParams) => void;
  getFilters: (searchParams: URLSearchParams) => {
    title: string;
    urlParam: string;
    value: string | null;
    displayFullLabel: boolean;
    key: string;
    customChipDisplay?: (value: string) => string;
    disableRemove?: boolean;
  }[];
}) => {
  const filters = getFilters(searchParams);
  const { t } = useTranslation("bulletin/filter");
  const bulletinId = searchParams.get("bulletinId");
  const bounds = searchParams.get("bounds");
  const view = searchParams.get("view");

  const handleClearAll = () => {
    const newParams = new URLSearchParams();
    if (bulletinId) newParams.set("bulletinId", bulletinId);
    if (view === "map") newParams.set("bounds", bounds || "");
    if (view) newParams.set("view", view);
    setSearchParams(newParams);
  };

  const activeFilters = filters.filter((f) => f.value !== null);
  if (activeFilters.length === 0) return null;
  else {
    const hideClearAll =
      view === "map" &&
      activeFilters.length === 1 &&
      activeFilters[0].urlParam === "bounds";

    return (
      <RenderFilterChips
        filterChipsActive={activeFilters.map((filter) =>
          filter.value !== null ? (
            <FilterChip
              key={filter.urlParam}
              title={t(`bulletin/filter:${filter.key}`)}
              value={t(`bulletin/${filter.key}:${filter.value}`)}
              onRemove={() => {
                searchParams.delete(filter.urlParam);
                setSearchParams(searchParams);
              }}
              displayFullLabel={filter.displayFullLabel}
              customChipValueDisplay={(value) =>
                filter.customChipDisplay
                  ? filter.customChipDisplay(value)
                  : value
              }
              disableRemove={filter.disableRemove}
            />
          ) : null,
        )}
        onClearAll={handleClearAll}
        hideClearAll={hideClearAll}
      />
    );
  }
};

const AlertFilters = ({
  isOpen,
  anchorEl,
  onClose,
}: {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}) => {
  const { policeServices } = useContext(AlertViewContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser } = useContext(AuthStateContext);
  const { t } = useTranslation("bulletin/filter");

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    value ? newSearchParams.set(key, value) : newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };

  const gridElements = [
    <Grid item xs={12} sm="auto" key="my-alerts">
      <FormControlLabel
        control={
          <Checkbox
            checked={searchParams.get("myAlerts") === "true"}
            onChange={(ev) => updateSearchParams("myAlerts", ev.target.checked)}
          />
        }
        label={t("myAlerts")}
        labelPlacement="end"
      />
    </Grid>,
    <Grid item xs={12} sm="auto" key="creator-group-id">
      <FormControlLabel
        control={
          <Checkbox
            checked={searchParams.get("creator_org_id") !== null}
            onChange={(ev) => {
              updateSearchParams(
                "creator_org_id",
                ev.target.checked ? currentUser?.rubi_org_id : null,
              );
            }}
          />
        }
        label={t("myAgencyBulletins")}
        labelPlacement="end"
      />
    </Grid>,
    <Grid item xs={12} key="incident-date-from">
      <DateRangePickerCustom
        labelFrom={t("incidentDateFrom")}
        labelTo={t("incidentDateTo")}
        startDate={searchParams.get("incidentStartDate") || ""}
        endDate={searchParams.get("incidentEndDate") || ""}
        onStartChange={(value) =>
          updateSearchParams("incidentStartDate", value)
        }
        onEndChange={(value) => updateSearchParams("incidentEndDate", value)}
      />
    </Grid>,
    <Grid item xs={12} key="issued-date-from">
      <DateRangePickerCustom
        labelFrom={t("issueDateFrom")}
        labelTo={t("issueDateTo")}
        startDate={searchParams.get("issuedStartDate") || ""}
        endDate={searchParams.get("issuedEndDate") || ""}
        onStartChange={(value) => updateSearchParams("issuedStartDate", value)}
        onEndChange={(value) => updateSearchParams("issuedEndDate", value)}
      />
    </Grid>,
    <Grid item xs={12} sm={6} key="bulletin-type">
      <Autocomplete
        id="select-bulletin-type"
        options={Object.values(BULLETIN_TYPE).map((type) => ({
          value: type,
          label: t(`bulletin/bulletinType:${type}`), // Translate bulletin type
        }))}
        value={
          Object.values(BULLETIN_TYPE)
            .map((type) => ({
              value: type,
              label: t(`bulletin/bulletinType:${type}`),
            }))
            .find(
              (option) => option.value === searchParams.get("bulletinType"),
            ) || null
        }
        onChange={(event, newValue) =>
          updateSearchParams("bulletinType", newValue?.value || null)
        }
        getOptionLabel={(option) => option.label} // Display translated label
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t("bulletinType")} // Translate the label
            fullWidth
          />
        )}
      />
    </Grid>,
    <Grid item xs={12} sm={6} key="associated-crime">
      <Autocomplete
        id="select-crime"
        options={crimeGroupedTypes}
        groupBy={(option) => t(`bulletin/associatedCrime:${option.group}`)} // Translate group names
        getOptionLabel={(option) =>
          t(`bulletin/associatedCrime:${option.crime}`)
        } // Translate crime names
        value={
          crimeGroupedTypes.find(
            (c) => c.crime === searchParams.get("associatedCrime"),
          ) || null
        }
        onChange={(event, newValue) =>
          updateSearchParams("associatedCrime", newValue?.crime)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t("associatedCrime")} // Translate label
            fullWidth
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const translatedCrime = t(`bulletin/associatedCrime:${option.crime}`); // Translate crime name
          const matches = match(translatedCrime, inputValue, {
            insideWords: true,
          });
          const parts = parse(translatedCrime, matches);

          return (
            <li {...props}>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </li>
          );
        }}
      />
    </Grid>,
    <Grid item xs={12} sm={6} key="police-service">
      <Autocomplete
        id="police-service-select-autocomplete"
        options={policeServices}
        getOptionLabel={(option) => option.name}
        value={
          policeServices.find(
            (p) => p.name === searchParams.get("policeService"),
          ) || null
        }
        onChange={(event, newValue) =>
          updateSearchParams("policeService", newValue?.name)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t("issuedPoliceService")}
            fullWidth
          />
        )}
      />
    </Grid>,
    <Grid item xs={12} key="clear-filters">
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => setSearchParams(new URLSearchParams())}
        sx={{
          borderRadius: 50,
        }}
      >
        {t("clear")}
      </Button>
    </Grid>,
  ];

  return (
    <SearchBarFilters
      isOpen={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      gridElements={gridElements}
    />
  );
};

export default AlertFilters;
