import {
  ArrowBack,
  ArrowForward,
  Cancel,
  Edit,
  ErrorOutline,
  Share,
  TaskAlt,
} from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "react-admin";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import ShareMultipleDialog from "./ShareMultipleDialog";
import { DisplayAlert } from "./add/DisplayAlert";
import ResolveBulletin from "./add/DisplayAlert/ResolveBulletin";
import "./alerts.scss";
import { ALERT_STATUS } from "./constants";
import { AlertViewContext, SingleAlertContext } from "./context";
import { updateToContextStructure } from "./utils";
import { useDrawer } from "../../common/SlideoutDrawer/DrawerContext";
import { apiAlertClient } from "../../common/api-client";
import { AuthStateContext } from "../../context";
import { BulletinResourceResponse, IUserPermission } from "../../types";
import { parseUserPermissions } from "../UserManagement/utils";

interface AlertSingleProps {
  mode: ALERT_STATUS;
  slideoutIdOverride?: number | null;
  queryNextPage?: () => void;
}

export const AlertSingle = ({
  mode,
  slideoutIdOverride,
  queryNextPage = () => {},
}: AlertSingleProps) => {
  const { currentItems } = useContext(AlertViewContext);
  const { currentUser } = useContext(AuthStateContext);
  const { alertProperties, setAlertProperties } =
    useContext(SingleAlertContext);
  const { closeDrawer } = useDrawer();

  const { id } = useParams();
  const idToUse = (id && parseInt(id)) || slideoutIdOverride;
  const isAlertSingleView = location.pathname.includes("/single");
  const [alertPermissions] = useState(
    currentUser &&
      parseUserPermissions(currentUser?.permissions as IUserPermission[]),
  );

  const [currentItem, setCurrentItem] = useState<BulletinResourceResponse>(
    {} as BulletinResourceResponse,
  );
  const alertCreatorId = currentItem?.creator_user_id;

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isResolveOpen, setIsResolveOpen] = useState(false);
  const [isCreator, setIsCreator] = useState(
    currentUser?.id === alertCreatorId,
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation("bulletin");
  const readOnly = alertPermissions
    ? !alertPermissions.canCreateBulletin
    : false;

  const { resolutions } = alertProperties;
  const latestPublishedResolution = resolutions
    ?.filter((resolution) => resolution.published)
    .sort((a, b) => {
      return (
        new Date(b.time_updated).getTime() - new Date(a.time_updated).getTime()
      );
    })[0];

  const hasUnpublishedResolution = resolutions?.some(
    (resolution) =>
      !resolution.published &&
      (resolution.details?.length > 0 ||
        Object.values(resolution.attachments).length > 0 ||
        resolution.reason?.length > 0),
  );

  const {
    type,
    issuing_police_service,
    associated_crime,
    occurrence_number,
    issued_time,
    description,
    pdf,
    evidence_files,
    incident_times,
  } = currentItem;
  const [searchParams, setSearchParams] = useSearchParams();
  const shareParam = searchParams.get("share");

  useEffect(() => {
    const searchParams = window.location.search;
    if (searchParams.includes("share=true")) {
      setIsShareModalOpen(true);
    }
  }, []);

  const navigate = useNavigate();

  const [theme] = useTheme();
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));

  const alertsOrDrafts = currentItem?.is_draft === 1 ? "drafts" : "alerts";

  if (isSmall && searchParams.get("bulletinId") != null && !isAlertSingleView) {
    navigate(
      `/rubialert/${alertsOrDrafts}/${searchParams.get("bulletinId")}/single`,
    );
  }
  const getAlertListById = (id) => {
    apiAlertClient
      .getBulletinById(id)
      .then((res) => {
        setCurrentItem(res);
        setIsLoaded(true);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const onEditAlert = () => {
    navigate(`/rubialert/${alertsOrDrafts}/${idToUse}/single/edit`);
  };
  const onShareAlert = () => {
    setIsShareModalOpen(true);
  };

  const shareModal = () => {
    if (!isShareModalOpen) return null;
    else
      return (
        <ShareMultipleDialog
          isShareModalOpenArg={isShareModalOpen}
          setIsShareModalOpenArg={setIsShareModalOpen}
          bulletinIDs={[currentItem?.id]}
        />
      );
  };

  const resolveCard = () => {
    if (isResolveOpen) {
      return (
        <ResolveBulletin
          bulletinType={currentItem?.type}
          bulletinId={currentItem?.id}
          setIsResolveOpen={setIsResolveOpen}
        />
      );
    }
  };

  const hasNextAlert = () => {
    if (currentItems) {
      let index = currentItems.map((item) => item.id).indexOf(idToUse ?? 0);
      index++;
      // if there's less than 5 alerts, load more page.
      if (index === currentItems.length - 5) {
        queryNextPage();
      }
      return index < currentItems.length;
    }
    return false;
  };

  const hasPreviousAlert = () => {
    if (currentItems) {
      let index = currentItems.map((item) => item.id).indexOf(idToUse ?? 0);
      index--;
      return index >= 0;
    }
    return false;
  };

  const onNextAlert = () => {
    if (currentItems) {
      let index = currentItems.map((item) => item.id).indexOf(idToUse ?? 0);
      index++;
      if (index < currentItems.length) {
        if (slideoutIdOverride) {
          const isShowingSimilar = searchParams.get("similarTo");
          if (isShowingSimilar) {
            setSearchParams((searchParams) => {
              searchParams.set("bulletinId", currentItems[index].id.toString());
              return searchParams;
            });
          } else {
            navigate(`/rubialert/alerts?bulletinId=${currentItems[index].id}`);
          }
        } else {
          navigate(
            `/rubialert/${alertsOrDrafts}/${currentItems[index].id}/single`,
          );
        }
      }
    }
    // if this is the last alert, use queryNextPage
    if (!hasNextAlert()) {
      queryNextPage();
    }
  };

  const onPreviousAlert = () => {
    if (currentItems) {
      let index = currentItems.map((item) => item.id).indexOf(idToUse ?? 0);
      index--;
      if (index >= 0) {
        if (slideoutIdOverride) {
          const isShowingSimilar = searchParams.get("similarTo");
          if (isShowingSimilar) {
            setSearchParams((searchParams) => {
              searchParams.set("bulletinId", currentItems[index].id.toString());
              return searchParams;
            });
          } else {
            navigate(`/rubialert/alerts?bulletinId=${currentItems[index].id}`);
          }
        } else {
          navigate(
            `/rubialert/${alertsOrDrafts}/${currentItems[index].id}/single`,
          );
        }
      }
    }
  };

  // binding left and right keys to next and previous alerts
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        onNextAlert();
      } else if (e.key === "ArrowLeft") {
        onPreviousAlert();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onNextAlert, onPreviousAlert, queryNextPage]);

  const ExitButton = ({ onExitAlert }: { onExitAlert: () => void }) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<Cancel />}
        onClick={onExitAlert}
        style={{
          marginTop: isSmall ? "" : "8px",
          height: "40px",
          // position: isSmall ? undefined : "fixed",
          zIndex: 2,
          borderRadius: isSmall ? "10px 0 0 10px" : "0 10px 10px 0",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
          backgroundColor: "#fff",
        }}
        disabled={isResolveOpen}
      >
        {isSmall ? null : t("exit_alert")}
      </Button>
    );
  };

  const ResolveButton = () => {
    if (
      mode !== ALERT_STATUS.DRAFT &&
      !latestPublishedResolution?.resolved &&
      (readOnly || (!readOnly && isCreator))
    ) {
      return (
        <Tooltip
          title={
            hasUnpublishedResolution
              ? "You have an unpublished resolution"
              : "Click to resolve"
          }
          placement="top"
        >
          <Button
            variant="contained"
            color={hasUnpublishedResolution ? "error" : "secondary"}
            size="medium"
            startIcon={
              hasUnpublishedResolution ? <ErrorOutline /> : <TaskAlt />
            }
            onClick={() => setIsResolveOpen(!isResolveOpen)}
            sx={{
              marginRight: "16px",
              paddingRight: isSmall ? 0 : undefined,
            }}
          >
            {isSmall
              ? null
              : hasUnpublishedResolution
                ? "Resolve (Pending Changes)"
                : "Resolve"}
          </Button>
        </Tooltip>
      );
    } else return null;
  };

  const ShareButton = () => {
    if (mode !== ALERT_STATUS.DRAFT && (readOnly || (!readOnly && isCreator)))
      return (
        <Button
          variant="outlined"
          color="secondary"
          size="medium"
          style={{
            marginRight: isSmall ? "4px" : "16px",
            paddingRight: isSmall ? 0 : "",
          }}
          startIcon={<Share />}
          onClick={onShareAlert}
        >
          {isSmall ? null : t("share")}
        </Button>
      );
    else return null;
  };

  const EditButton = () => {
    if (readOnly || (!readOnly && isCreator))
      return (
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          style={{
            marginRight: isSmall ? "4px" : "16px",
            paddingRight: isSmall ? 0 : "",
          }}
          startIcon={<Edit />}
          onClick={onEditAlert}
          disabled={isResolveOpen}
        >
          {isSmall ? null : t("edit")}
        </Button>
      );
    else return null;
  };

  const ShowSimilarButton = () => {
    const isShowingSimilar = searchParams.get("similarTo");

    if ((readOnly || mode !== ALERT_STATUS.DRAFT) && !isShowingSimilar) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{
            marginRight: isSmall ? "4px" : "16px",
            paddingRight: isSmall ? 0 : "",
          }}
          onClick={() => {
            setSearchParams((searchParams) => {
              searchParams.set("similarTo", currentItem?.id.toString());
              return searchParams;
            });
          }}
        >
          Find Similar
        </Button>
      );
    } else if (
      (readOnly || mode !== ALERT_STATUS.DRAFT) &&
      isShowingSimilar === currentItem?.id?.toString()
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{
            marginRight: isSmall ? "4px" : "16px",
            paddingRight: isSmall ? 0 : "",
          }}
          startIcon={<Cancel />}
          onClick={() => {
            setSearchParams((searchParams) => {
              searchParams.delete("similarTo");
              return searchParams;
            });
          }}
        >
          Hide Similar
        </Button>
      );
    } else if (
      (readOnly || mode !== ALERT_STATUS.DRAFT) &&
      isShowingSimilar !== currentItem?.id?.toString()
    ) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Chip
            label={`Similar to ${isShowingSimilar}`}
            color="success"
            variant="outlined"
            title="Go back to reference bulletin"
            onClick={() => {
              setSearchParams((searchParams) => {
                // set bulletinId to similarTo
                if (searchParams.get("similarTo")) {
                  searchParams.set(
                    "bulletinId",
                    searchParams.get("similarTo")!,
                  );
                }
                return searchParams;
              });
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              setSearchParams((searchParams) => {
                searchParams.set("similarTo", currentItem?.id.toString());
                return searchParams;
              });
            }}
          >
            Find Similar
          </Button>
        </div>
      );
    } else return null;
  };

  const PreviousButton = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        className="border-radius-rounded p-0"
        size="medium"
        onClick={onPreviousAlert}
        style={{
          minWidth: "48px",
          minHeight: "48px",
        }}
        disabled={isResolveOpen || !hasPreviousAlert()}
      >
        <ArrowBack />
      </Button>
    );
  };

  const NextButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        className="border-radius-rounded"
        onClick={onNextAlert}
        disabled={isResolveOpen || !hasNextAlert()}
        style={{
          minWidth: "48px",
          minHeight: "48px",
          padding: 0,
        }}
      >
        <ArrowForward />
      </Button>
    );
  };

  const desktopButtons = () => {
    const resolveButton = ResolveButton();
    const shareButton = ShareButton();
    const editButton = EditButton();
    const showSimilarButton = ShowSimilarButton();
    const draftsNavigationButton = ExitButton({
      onExitAlert: () => {
        navigate(`/rubialert/${alertsOrDrafts}`);
      },
    });

    if (isSmall) return null;
    if (resolveButton || shareButton || editButton || draftsNavigationButton) {
      return (
        <div
          className={
            resolveButton || shareButton || editButton || showSimilarButton
              ? "alert-view-heading"
              : ""
          }
        >
          {(mode === ALERT_STATUS.DRAFT ||
            slideoutIdOverride === undefined) && (
            <ExitButton
              onExitAlert={() => {
                navigate(`/rubialert/${alertsOrDrafts}`);
              }}
            />
          )}
          <div>
            {resolveButton}
            {shareButton}
            {editButton}
          </div>
          {slideoutIdOverride && (
            <div style={{ display: "flex" }}>
              {showSimilarButton}
              {/* add a button to open in new tab */}
              <IconButton
                aria-label="open in new tab"
                style={{
                  marginRight: isSmall ? "4px" : "16px",
                  paddingRight: isSmall ? 0 : "",
                }}
                color="primary"
                onClick={() => {
                  window.open(
                    `/rubialert/${alertsOrDrafts}/${currentItem?.id}/single`,
                    "_blank",
                  );
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </div>
          )}
        </div>
      );
    }
  };

  const mobileTopButtons = () => {
    return (
      <div>
        <div
          style={{
            display: "inline-flex", // "flex
            justifyContent: "space-between",
            width: "100%",
            position: "fixed",
            top: "42px",
            left: "0",
            paddingLeft: "16px",
            paddingTop: "16px",
            paddingBottom: "16px",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            zIndex: 2,
            alignItems: "center",
          }}
        >
          <div>
            <ResolveButton />
            <ShareButton />
            <EditButton />
          </div>
          <div>
            <ExitButton
              onExitAlert={() => {
                navigate(`/rubialert/${alertsOrDrafts}`);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const mobileBottomButtons = () => {
    const showBottomButtoms = hasPreviousAlert() || hasNextAlert();
    if (!showBottomButtoms) return null;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          position: "fixed",
          bottom: "0",
          left: "0",
          padding: "16px",
          backgroundColor: "#fff",
          boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <PreviousButton />
        <NextButton />
      </div>
    );
  };

  const desktopBottomButtons = () => {
    const showBottomButtoms = hasPreviousAlert() || hasNextAlert();
    if (!showBottomButtoms) return null;
    return (
      <div className="desktop-bottom-buttons d-flex mb-3">
        <div className="mr-3">
          <PreviousButton />
        </div>
        <div>
          <NextButton />
        </div>
      </div>
    );
  };

  // useEffects
  useEffect(() => {
    const isCreator =
      currentUser?.id?.toString() === alertCreatorId?.toString();
    setIsCreator(isCreator);
  }, [currentUser?.id, alertCreatorId]);

  // on navigation to a new alert
  useEffect(() => {
    setIsResolveOpen(false);
    if (Object.keys(currentItem).length > 0) {
      const alertProperties = updateToContextStructure(currentItem);
      setAlertProperties(alertProperties);
    }
  }, [currentItem]);

  useEffect(() => {
    if (idToUse) getAlertListById(idToUse);
  }, [idToUse]);

  if (!isLoaded)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <>
      {!isSmall && desktopButtons()}
      {isSmall && mobileTopButtons()}
      <div className="alert-container">
        {isResolveOpen && resolveCard()}
        {currentItem && (
          <DisplayAlert
            mode="add"
            issuing_police_service={issuing_police_service?.name}
            associatedCrimes={associated_crime}
            occurrenceNumbers={occurrence_number}
            issuedTime={issued_time}
            description={description}
            pdf={pdf}
            evidences={evidence_files}
          />
        )}
      </div>
      {isShareModalOpen && shareModal()}
      {isSmall && mobileBottomButtons()}
      {!isSmall && slideoutIdOverride && desktopBottomButtons()}
    </>
  );
};
