// External Importa
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
//Internal Imports
import { useTranslation } from "react-i18next";

import { APP_ALERT_SERVER_URL } from "../../../../environment";
import { IPdfFile } from "../../../../types";

interface DisplayAttachedPdfProps {
  pdfFile: IPdfFile | {};
  mode: "add" | "view" | "parade";
  view_token: string | undefined;
}

const DisplayAttachedPDF = (props: DisplayAttachedPdfProps) => {
  const [expandedAccordion, setExpandedAccordion] =
    React.useState<boolean>(false);
  const { t } = useTranslation("bulletin");

  const getPdfUrl = () => {
    if (!("download_url" in props.pdfFile)) return "";
    const url = new URL(APP_ALERT_SERVER_URL);
    url.pathname = props.pdfFile.download_url;
    if (props.mode === "view" && props.view_token) {
      url.searchParams.set("access_token", props.view_token);
    }
    return url.toString();
  };

  return (
    <>
      {props.pdfFile && "download_url" in props.pdfFile && (
        <Accordion
          disableGutters
          elevation={3}
          expanded={expandedAccordion}
          onChange={() => setExpandedAccordion((prevValue) => !prevValue)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">
              <b>{t("attached_pdf")}</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {<embed src={getPdfUrl()} width="100%" height="1050px" />}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default DisplayAttachedPDF;
