import "./alerts.scss";

import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { DisplayAlert } from "./add/DisplayAlert";
import { SingleAlertContext } from "./context";
import { updateToContextStructure } from "./utils";
import { apiAlertClient } from "../../common/api-client";

export const AlertView = () => {
  const { setAlertProperties } = useContext(SingleAlertContext);
  const [searchParams] = useSearchParams();
  const view_token = searchParams.get("view_token") ?? "";

  const [currentItem, setCurrentItem] = useState<any>(null);

  const getBulletinByToken = (token) => {
    apiAlertClient
      .getBulletinByToken(token)
      .then((res) => {
        setCurrentItem(res);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (!view_token || view_token === "") return;
    getBulletinByToken(view_token);
  }, [view_token]);

  useEffect(() => {
    if (currentItem) {
      const alertProperties = updateToContextStructure(currentItem);
      setAlertProperties(alertProperties);
    }
  }, [currentItem]);

  return (
    <div className="alert-container">
      {currentItem && (
        <DisplayAlert
          mode="view"
          view_token={view_token}
          issuing_police_service={currentItem.issuing_police_service.name}
          associatedCrimes={currentItem.associated_crime}
          occurrenceNumbers={currentItem.occurrence_number}
          issuedTime={currentItem.issued_time}
          description={currentItem.description}
          pdf={currentItem.pdf}
          evidences={currentItem.evidence_files}
        />
      )}
    </div>
  );
};
